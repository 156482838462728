import { useState } from "react";
import { useAppContext } from "../../contexts/AppContext";

export default function useProjects() {
  const {
    projectTypes: projectTypesStore,
    selectedProjectTypeId,
    projects: projectsStore,
    activeProjects: activeProjectsStore,
    inactiveProjects: inactiveProjectsStore,
    dispatch,
  } = useAppContext();

  const getProjectsTypes = () => {
    if (projectTypesStore === "undefined" || projectTypesStore === "{}") {
      return null;
    }
    return projectTypesStore;
  };

  const [projectTypes, setProjectTypes] = useState(getProjectsTypes());

  const saveProjectsTypes = (projectTypes) => {
    dispatch({
      type: "EDIT_PROJECT_TYPES",
      value: projectTypes,
    });

    setProjectTypes(projectTypes);
  };

  const getSelectedProjectType = () => {
    if (!selectedProjectTypeId) {
      return "ALL";
    }
    return selectedProjectTypeId;
  };
  const [selectedProjectType, setSelectedProjectType] = useState(
    getSelectedProjectType()
  );

  const saveSelectedProjectType = (projectType) => {
    dispatch({ type: "EDIT_SELECTED_PROJECT_TYPE_ID", value: projectType });
    setSelectedProjectType(projectType);
  };

  const getProjects = () => {
    if (!projectsStore) {
      return null;
    }
    return projectsStore;
  };

  const [projects, setProjects] = useState(getProjects());

  const saveProjects = (projects) => {
    dispatch({
      type: "EDIT_PROJECTS",
      value: projects,
    });
    setProjects(projects);
  };

  const getActiveProjects = () => {
    if (!activeProjectsStore) {
      return null;
    }
    return activeProjectsStore;
  };

  const [activeProjects, setActiveProjects] = useState(getActiveProjects());

  const saveActiveProjects = (projects) => {
    dispatch({
      type: "EDIT_PROJECTS",
      value: projects,
    });
    setActiveProjects(projects);
  };

  const getInactiveProjects = () => {
    if (!inactiveProjectsStore) {
      return null;
    }
    return inactiveProjectsStore;
  };

  const [inactiveProjects, setInactiveProjects] = useState(
    getInactiveProjects()
  );

  const saveInactiveProjects = (projects) => {
    dispatch({ type: "EDIT_INACTIVE_PROJECTS", value: projects });
    setInactiveProjects(projects);
  };

  const getToRefreshProjects = () => {
    const str = localStorage.getItem("toRefreshProjects");
    if (str === "undefined" || str === "{}") {
      return null;
    }
    const obj = JSON.parse(str);
    return obj;
  };

  const [toRefreshProjects, setToRefreshProjects] = useState(
    getToRefreshProjects()
  );

  const saveToRefreshProjects = (value) => {
    localStorage.setItem("toRefreshProjects", JSON.stringify(value));
    setToRefreshProjects(value);
  };

  const getToRefreshActiveProjects = () => {
    const str = localStorage.getItem("toRefreshActiveProjects");
    if (str === "undefined" || str === "{}") {
      return null;
    }
    const obj = JSON.parse(str);
    return obj;
  };

  const [toRefreshActiveProjects, setToRefreshActiveProjects] = useState(
    getToRefreshActiveProjects()
  );

  const saveToRefreshActiveProjects = (value) => {
    localStorage.setItem("toRefreshActiveProjects", JSON.stringify(value));
    setToRefreshActiveProjects(value);
  };

  const getToRefreshInactiveProjects = () => {
    const str = localStorage.getItem("toRefreshInactiveProjects");
    if (str === "undefined" || str === "{}") {
      return null;
    }
    const obj = JSON.parse(str);
    return obj;
  };

  const [toRefreshInactiveProjects, setToRefreshInactiveProjects] = useState(
    getToRefreshInactiveProjects()
  );

  const saveToRefreshInactiveProjects = (value) => {
    localStorage.setItem("toRefreshInactiveProjects", JSON.stringify(value));
    setToRefreshInactiveProjects(value);
  };

  const getProjectsByProjectNameInfo = () => {
    const str = localStorage.getItem("ProjectsByProjectNameInfo");
    if (str === "undefined" || str === "{}") {
      return null;
    }
    const obj = JSON.parse(str);
    return obj;
  };

  const [projectsByProjectNameInfo, setProjectsByProjectNameInfo] = useState(
    getProjectsByProjectNameInfo()
  );

  const saveProjectsByProjectNameInfo = (value) => {
    localStorage.setItem("ProjectsByProjectNameInfo", JSON.stringify(value));
    setProjectsByProjectNameInfo(value);
  };

  return {
    setProjectTypes: saveProjectsTypes,
    setSelectedProjectType: saveSelectedProjectType,
    setProjects: saveProjects,
    setProjectsByProjectNameInfo: saveProjectsByProjectNameInfo,
    getProjects,
    getActiveProjects,
    setActiveProjects: saveActiveProjects,
    getInactiveProjects,
    setInactiveProjects: saveInactiveProjects,
    setToRefreshProjects: saveToRefreshProjects,
    setToRefreshActiveProjects: saveToRefreshActiveProjects,
    setToRefreshInactiveProjects: saveToRefreshInactiveProjects,
    toRefreshProjects,
    toRefreshActiveProjects,
    toRefreshInactiveProjects,
    projectTypes,
    selectedProjectType,
    projects,
    activeProjects,
    inactiveProjects,
    projectsByProjectNameInfo,
  };
}
