import React, { useState, useEffect } from "react";
import ErrorMessage from "../common/ErrorMessage";
import Multiselect from "multiselect-react-dropdown";
import useProjects from "../hooks/useProjects";
import ADO from "../../services/ADO";
import useProjectDetails from "../hooks/useProjectDetails";
import CreatableSelect from "react-select/creatable";
import { useAppContext } from "../../contexts/AppContext";
import { useParams } from "react-router-dom";
import {
  MenuItem,
  Autocomplete,
  TextField,
  Tooltip,
  Chip,
} from "@mui/material";
import "../css/ADOInfo.css";

const getADOProjectsListRequest = async (token, program) => {
  try {
    const response = await ADO.getADOProjectsList(token, program);
    return response;
  } catch (error) {
    return null;
  }
};

const getADOProjectsFieldsRequest = async (token, program, programValues) => {
  let queryParamsString = "";

  programValues.forEach((program) => {
    return (queryParamsString += `adoProject=${program}&`);
  });

  queryParamsString.replaceAll(" ", "");
  queryParamsString = deleteLastAmpersand(queryParamsString);

  try {
    const response = await ADO.getADOProjectFields(
      token,
      program,
      queryParamsString
    );
    return response;
  } catch (error) {
    return null;
  }
};

const getADOAreaValuesRequest = async (token, program, programValues) => {
  try {
    const response = await ADO.getADOAreaValuesAndReleases(
      token,
      program,
      programValues
    );
    return response;
  } catch (error) {
    return null;
  }
};

const getADOProjectsWorkItemTypesRequest = async (
  token,
  program,
  programValues
) => {
  let queryParamsString = "";

  programValues.forEach((program) => {
    return (queryParamsString += `adoProject=${program}&`);
  });

  queryParamsString.replaceAll(" ", "");
  queryParamsString = deleteLastAmpersand(queryParamsString);

  try {
    const response = await ADO.getADOProjectWorkItemTypes(
      token,
      program,
      queryParamsString
    );
    return response;
  } catch (error) {
    return null;
  }
};

const getADOTestPlanReleasesRequest = async (token, program, projects) => {
  let queryParamsString = projects.join(",");

  try {
    const response = await ADO.getADOTestPlanReleases(
      token,
      program,
      queryParamsString
    );
    return response;
  } catch (error) {
    return null;
  }
};

const deleteLastAmpersand = (string) => {
  let index = string.lastIndexOf("&");

  if (index !== -1) {
    return string.substring(0, index) + string.substring(index + 1);
  }

  return string;
};

const ADOInfo = ({
  token,
  programId,
  editMode,
  addNewProjectFlag,
  handleADORequestData,
  set_ADO_Project_Selected,
  setTestPlansLoading,
  setTestPlansOptions,
}) => {
  const { projectTypes } = useProjects();
  const { projectDetails } = useProjectDetails();
  const { projectId } = useParams();
  const [loading, setLoading] = useState(false);
  const [isAllPopulated, setIsAllPopulated] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [programName, setProgramName] = useState();
  const [projectOptions, setProjectOptions] = useState([]);
  const [programFieldOptions, setProgramFieldOptions] = useState();
  const [programWorkItemTypeOptions, setProgramWorkItemTypeOptions] =
    useState();
  const [areaValuesOptions, setAreaValuesOptions] = useState([]);
  const [releasesValuesOptions, setReleasesValuesOptions] = useState([]);
  const [testPlanReleasesOptions, setTestPlanReleasesOptions] = useState([]);
  const [selectedProjectValue, setSelectedProjectValue] = useState([]);
  const [selectedAreaFieldValue, setSelectedAreaFieldValue] = useState([]);
  const [selectedAreaValues, setSelectedAreaValues] = useState();
  const [selectedWorkItemTypeValue, setSelectedWorkItemTypeValue] = useState();
  const [selectedTargetReleaseValue, setSelectedTargetReleaseValue] = useState(
    []
  );
  const [selectedReleaseValues, setSelectedReleaseValues] = useState();
  const [selectedReleaseFoundValue, setSelectedReleaseFoundValue] = useState();
  const [selectedEnvironmentValue, setSelectedEnvironmentValue] = useState();
  const [selectedProductValue, setSelectedProductValue] = useState();
  const { currentSelectedProjectTypeId, selectedProjectTypeId } =
    useAppContext();

  useEffect(() => {
    if (projectDetails && selectedProjectValue?.length >= 2) {
      handleProjectAppendixNames();
    }
  }, [selectedProjectValue]);

  useEffect(() => {
    if (projectDetails && !editMode) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  });

  useEffect(() => {
    const handlePopulateFieldsCall = async () => {
      try {
        let projects = projectDetails.lpAdoRelations.map((list) => {
          return list.adoProjName;
        });
        let areaFields = projectDetails.lpAdoRelations.map((list) => {
          return {
            fieldName: list.adoAreaFieldName,
            fieldRefrenceName: list.adoAreaFieldRefName,
            program: list.adoProjName,
          };
        });
        let targetReleaseField = projectDetails.lpAdoRelations.map((list) => {
          return {
            fieldName: list.adoTargetReleaseFieldName,
            fieldRefrenceName: list.adoTargetReleaseFieldRefName,
            program: list.adoProjName,
          };
        });

        let projectFieldsResponse = await handleProjectFieldsRequest(projects);
        let areaValuesResponse = await handleAreaValuesRequest(areaFields);
        let releasesValuesResponse = await handleReleasesRequest(
          targetReleaseField
        );
      } catch (error) {
        return null;
      }
    };

    if (projectId !== "new" && projectDetails) {
      handlePopulateFieldsCall();
      setADORequestData(testPlanReleasesOptions);
    }
  }, []);

  useEffect(() => {
    const handleADOProjectsList = async () => {
      let checkedProgram;

      if (!programName) {
        checkedProgram = checkProgramName();
      }

      let projects = await getADOProjectsListRequest(
        token,
        programName ? programName : checkedProgram
      );

      if (projects.message) {
        return null;
      } else {
        let projectOptions = Object.values(projects)[0];
        let keyName = Object.keys(projects.adoProjects)[0];

        setProjectOptions(projectOptions[keyName]);
      }
    };

    let programName = getProgramName(programId);
    setProgramName(programName);

    if (projectId !== "new" && projectDetails) {
      handleADOProjectsList();
      return handlePopulateFields();
    } else {
      handleADOProjectsList();
    }

    cleanUpSelectedFieldsValues(true);
  }, [programId]);

  useEffect(() => {
    if (!projectDetails) {
      if (
        selectedAreaFieldValue.length !== 0 &&
        selectedAreaFieldValue.length === selectedProjectValue.length
      ) {
        handleAreaValuesRequest();
      }

      if (
        selectedTargetReleaseValue.length !== 0 &&
        selectedTargetReleaseValue.length === selectedProjectValue.length
      ) {
        handleReleasesRequest();
      }
    } else if (projectDetails) {
      if (
        selectedAreaFieldValue &&
        selectedAreaFieldValue.length !== 0 &&
        selectedAreaFieldValue &&
        selectedAreaFieldValue.length === selectedProjectValue.length
      ) {
        handleAreaValuesRequest();
      }

      if (
        selectedTargetReleaseValue &&
        selectedTargetReleaseValue.length !== 0 &&
        selectedTargetReleaseValue &&
        selectedTargetReleaseValue.length === selectedProjectValue.length
      ) {
        handleReleasesRequest();
      }
    }
  }, [selectedAreaFieldValue, selectedTargetReleaseValue]);

  useEffect(() => {
    if (
      selectedProjectValue &&
      selectedProjectValue.length <= 0 &&
      !projectDetails
    ) {
      cleanUpSelectedFieldsValues(false);
    } else {
      setADORequestData(testPlanReleasesOptions);
    }
  }, [
    selectedProjectValue,
    selectedAreaFieldValue,
    selectedAreaValues,
    selectedWorkItemTypeValue,
    selectedTargetReleaseValue,
    selectedReleaseValues,
    selectedReleaseFoundValue,
    selectedEnvironmentValue,
    selectedProductValue,
  ]);

  useEffect(() => {
    const handleADOTestPlanReleasesList = async () => {
      let checkedProgram;

      if (!programName) {
        checkedProgram = checkProgramName();
      }

      let testPlanReleases = await getADOTestPlanReleasesRequest(
        token,
        programName ? programName : checkedProgram,
        selectedProjectValue
      );

      if (testPlanReleases.message) {
        return null;
      } else {
        let testPlanReleasesOptionsValues = testPlanReleases?.map((value) => {
          let string = value.id;
          let pos = string.split(":-:");
          let finalString = pos[1];

          return {
            id: value.id,
            name: value.name,
            program: finalString,
          };
        });

        setTestPlanReleasesOptions(testPlanReleases);
        setTestPlansOptions(testPlanReleasesOptionsValues);
        setADORequestData(testPlanReleases);
        setTestPlansLoading(false);
      }
    };

    if (selectedProjectValue && selectedProjectValue.length) {
      setTestPlansLoading(true);
      handleADOTestPlanReleasesList();
    }
  }, [selectedProjectValue]);

  useEffect(() => {
    set_ADO_Project_Selected(selectedProjectValue);
  }, [selectedProjectValue]);

  const handlePopulateFields = () => {
    let projects = projectDetails.lpAdoRelations?.map((list) => {
      return list.adoProjName;
    });
    let areaFields = projectDetails.lpAdoRelations?.map((list) => {
      return {
        fieldName: list.adoAreaFieldName,
        fieldRefrenceName: list.adoAreaFieldRefName,
        program: list.adoProjName,
      };
    });
    let areaFieldsValues = projectDetails.lpAdoRelations?.map((list) => {
      return list.adoAreaFieldValue.map((areaField) => {
        return {
          fieldName: areaField,
          fieldRefrenceName: areaField,
          program: list.adoProjName,
        };
      });
    });
    let workItemTypes = projectDetails.lpAdoRelations?.map((list) => {
      return {
        fieldName: list.adoWorkItemType,
        fieldRefrenceName: list.adoWorkItemRefType,
        program: list.adoProjName,
      };
    });
    let targetReleaseField = projectDetails.lpAdoRelations?.map((list) => {
      return {
        fieldName: list.adoTargetReleaseFieldName,
        fieldRefrenceName: list.adoTargetReleaseFieldRefName,
        program: list.adoProjName,
      };
    });
    let releases = projectDetails.lpAdoRelations?.map((list) => {
      return {
        fieldName: list.adoTargetReleaseValues,
        fieldRefrenceName: list.adoTargetReleaseValues,
        program: list.adoProjName,
      };
    });
    let releaseFoundField = projectDetails.lpAdoRelations?.map((list) => {
      return {
        fieldName: list.adoReleaseFoundFieldName,
        fieldRefrenceName: list.adoReleaseFoundFieldRefName,
        program: list.adoProjName,
      };
    });
    let environmentField = projectDetails.lpAdoRelations?.map((list) => {
      return {
        fieldName: list.adoEnvironmentFieldName,
        fieldRefrenceName: list.adoEnvironmentFieldRefName,
        program: list.adoProjName,
      };
    });
    let productField = projectDetails.lpAdoRelations?.map((list) => {
      return {
        fieldName: list.adoProductFieldName,
        fieldRefrenceName: list.adoProductFieldRefName,
        program: list.adoProjName,
      };
    });

    if (releases && releases[0].fieldName.length > 1) {
      let newValues = releases[0].fieldName;
      let newPrograms = releases[0].program;

      let newOptions = newValues.map((value) => {
        return {
          fieldName: value,
          fieldRefrenceName: value,
          program: newPrograms,
        };
      });

      releases = newOptions;
    }

    if (projectDetails) {
      setSelectedProjectValue(checkEmptyValues(projects));
      setSelectedAreaFieldValue(checkEmptyValues(areaFields));
      setSelectedAreaValues(checkEmptyValues(areaFieldsValues));
      setSelectedWorkItemTypeValue(checkEmptyValues(workItemTypes));
      setSelectedTargetReleaseValue(checkEmptyValues(targetReleaseField));
      setSelectedReleaseValues(checkEmptyValues(releases));
      setSelectedReleaseFoundValue(checkEmptyValues(releaseFoundField));
      setSelectedEnvironmentValue(checkEmptyValues(environmentField));
      setSelectedProductValue(checkEmptyValues(productField));
    }
  };

  const handleProjectFieldsRequest = async (values) => {
    if (values.length) {
      setLoading(true);

      let checkedProgram;

      if (!programName) {
        checkedProgram = checkProgramName();
      }

      let projectFieldsRequest = await getADOProjectsFieldsRequest(
        token,
        programName ? programName : checkedProgram,
        values
      );

      if (projectFieldsRequest.message) {
        setLoading(false);
        return;
      }

      if (projectFieldsRequest) {
        let programFieldOptions = [];
        let projectFieldsRequestData = Object.entries(
          projectFieldsRequest.adoProjectFields
        );

        projectFieldsRequestData.forEach((project) => {
          return project[1].forEach((option) => {
            option.program = project[0];
            return programFieldOptions.push(option);
          });
        });
        let programFieldOptionsWithNoDuplicates =
          deleteDuplicates(programFieldOptions);
        let repeatedLabelsWithDifferentProgram =
          findRepeatedLabelsWithDifferentProgram(
            programFieldOptionsWithNoDuplicates
          );

        programFieldOptionsWithNoDuplicates.forEach((program) => {
          let value = program.fieldName;
          let programString = program.program;

          return (program.fieldName = `${value} - [${programString}]`);
        });

        setProgramFieldOptions(programFieldOptionsWithNoDuplicates);

        let workItemTypeResponse = await getADOProjectsWorkItemTypesRequest(
          token,
          programName ? programName : checkedProgram,
          values
        );

        if (workItemTypeResponse) {
          let programWorkItemTypeOptions = [];
          let projectWorkItemTypeRequestData = Object.entries(
            workItemTypeResponse.adoProjectFields
          );

          projectWorkItemTypeRequestData.forEach((project) => {
            return project[1].forEach((option) => {
              option.program = project[0];
              return programWorkItemTypeOptions.push(option);
            });
          });

          let programWorkItemTypeOptionsWithNoDuplicates = deleteDuplicates(
            programWorkItemTypeOptions
          );
          let repeatedLabelsWithDifferentProgram =
            findRepeatedLabelsWithDifferentProgram(
              programWorkItemTypeOptionsWithNoDuplicates
            );

          programWorkItemTypeOptionsWithNoDuplicates.forEach((program) => {
            let value = program.fieldName;
            let programString = program.program;

            if (repeatedLabelsWithDifferentProgram.includes(value)) {
              return (program.fieldName = `${value} - [${programString}]`);
            }
          });

          setProgramWorkItemTypeOptions(
            programWorkItemTypeOptionsWithNoDuplicates
          );
          setLoading(false);
        }
      }
    }
  };

  const handleAreaValuesRequest = async (areaFields) => {
    setLoading(true);

    let hasAreaFieldsValues = areaFields ? areaFields : selectedAreaFieldValue;
    let dataObject = {
      adoProjectFields: {},
    };

    hasAreaFieldsValues.forEach((option) => {
      let dataOption = { ...option };
      delete dataOption.program;
      dataObject.adoProjectFields[option.program] = dataOption;
    });

    let queryParams = JSON.stringify(dataObject);
    let checkedProgram;

    if (!programName) {
      checkedProgram = checkProgramName();
    }

    let areaValuesRequest = await getADOAreaValuesRequest(
      token,
      programName ? programName : checkedProgram,
      queryParams
    );

    if (areaValuesRequest) {
      let areaValues = Object.entries(areaValuesRequest);
      let valuesListData = areaValues.map((program) => {
        return [program[0], Object.values(program[1])];
      });
      let finalValues = [];

      valuesListData.forEach((valuesList) => {
        valuesList[1].forEach((value) => {
          value.forEach((singleValue) => {
            finalValues.push({
              fieldName: singleValue,
              fieldReferenceName: singleValue,
              program: valuesList[0],
            });
          });
        });
      });

      setAreaValuesOptions(finalValues);
      setLoading(false);
    }
  };

  const handleReleasesRequest = async (targetFieldsValues) => {
    setLoading(true);

    let hasTargetFieldsValues = targetFieldsValues
      ? targetFieldsValues
      : selectedTargetReleaseValue;
    let dataObject = {
      adoProjectFields: {},
    };

    hasTargetFieldsValues.forEach((option) => {
      let dataOption = { ...option };
      delete dataOption.program;
      dataObject.adoProjectFields[option.program] = dataOption;
    });

    let queryParams = JSON.stringify(dataObject);
    let checkedProgram;

    if (!programName) {
      checkedProgram = checkProgramName();
    }

    let releasesValuesRequest = await getADOAreaValuesRequest(
      token,
      programName ? programName : checkedProgram,
      queryParams
    );

    if (releasesValuesRequest) {
      let releasesValues = Object.entries(releasesValuesRequest);
      let valuesListData = releasesValues.map((program) => {
        return [program[0], Object.values(program[1])];
      });
      let finalValues = [];

      valuesListData.forEach((valuesList) => {
        valuesList[1].forEach((value) => {
          value.forEach((singleValue) => {
            finalValues.push({
              fieldName: singleValue,
              fieldReferenceName: singleValue,
              program: valuesList[0],
            });
          });
        });
      });

      setReleasesValuesOptions(finalValues);
      setLoading(false);
    }
  };

  const handleProjectAppendixNames = () => {
    setSelectedAreaFieldValue((prevValues) => {
      return addProjectAppendix(prevValues);
    });
    setSelectedAreaValues((prevValues) => {
      return addProjectAppendix(prevValues);
    });
    setSelectedWorkItemTypeValue((prevValues) => {
      return addProjectAppendix(prevValues);
    });
    setSelectedTargetReleaseValue((prevValues) => {
      return addProjectAppendix(prevValues);
    });
    setSelectedReleaseValues((prevValues) => {
      return addProjectAppendix(prevValues);
    });
    setSelectedReleaseFoundValue((prevValues) => {
      return addProjectAppendix(prevValues);
    });
    setSelectedEnvironmentValue((prevValues) => {
      return addProjectAppendix(prevValues);
    });
    setSelectedProductValue((prevValues) => {
      return addProjectAppendix(prevValues);
    });
  };

  const setADORequestData = (testPlanReleasesData) => {
    let dataArray = [];
    let releases = [];

    selectedProjectValue &&
      selectedProjectValue.forEach((project) => {
        let areaField = selectedAreaFieldValue?.find((value) => {
          return value.program === project;
        });

        let areaValuesArray = [];
        selectedAreaValues?.forEach((value) => {
          if (value.program === project) {
            let finalValue = Array.isArray(value.fieldName)
              ? value.fieldName[0]
              : value.fieldName;

            return areaValuesArray.push(finalValue);
          }
        });
        let workItemType = selectedWorkItemTypeValue?.find((value) => {
          return value.program === project;
        });
        let targetReleaseField = selectedTargetReleaseValue?.find((value) => {
          return value.program === project;
        });

        if (
          selectedReleaseValues &&
          selectedReleaseValues.length > 0 &&
          selectedReleaseValues[0].value
        ) {
          releases = selectedReleaseValues
            ?.map((value) => {
              return value.value;
            })
            .filter((element) => element !== undefined);
        } else {
          releases = selectedReleaseValues
            ?.map((value) => {
              return value.fieldName[0];
            })
            .filter((element) => element !== undefined);
        }

        let releaseFound = selectedReleaseFoundValue?.find((value) => {
          return value.program === project;
        });
        let environment = selectedEnvironmentValue?.find((value) => {
          return value.program === project;
        });
        let product = selectedProductValue?.find((value) => {
          return value.program === project;
        });
        let testPlanReleases = testPlanReleasesData?.map((value) => {
          let string = value.id;
          let pos = string.split(":-:");
          let finalString = pos[1];

          return {
            id: value.id,
            name: value.name,
            program: finalString,
          };
        });

        let hasUniqueReleases = Array.isArray(releases && releases[0]);

        if (hasUniqueReleases) {
          let finalValues = [...releases[0]];

          releases.forEach((value, index) => {
            if (index !== 0) {
              finalValues.push(value);
            }
          });

          releases[0] = finalValues;
        }

        let dataObject = {
          adoOrgName: programName,
          adoProjName: project,
          adoAreaFieldName: areaField?.fieldName,
          adoAreaFieldRefName: areaField?.fieldRefrenceName,
          adoAreaFieldValue: areaValuesArray,
          adoWorkItemType: workItemType?.fieldName,
          adoWorkItemRefType: workItemType?.fieldRefrenceName,
          adoTargetReleaseFieldName: targetReleaseField?.fieldName,
          adoTargetReleaseFieldRefName: targetReleaseField?.fieldRefrenceName,
          adoTargetReleaseValues: addNewProjectFlag
            ? releases
            : hasUniqueReleases
            ? releases[0]
            : releases,
          adoReleaseFoundFieldName: releaseFound?.fieldName,
          adoReleaseFoundFieldRefName: releaseFound?.fieldRefrenceName,
          adoEnvironmentFieldName: environment?.fieldName,
          adoEnvironmentFieldRefName: environment?.fieldRefrenceName,
          adoProductFieldName: product?.fieldName,
          adoProductFieldRefName: product?.fieldRefrenceName,
          adoTestPlanRelease: [],
          adoTestPlanReleaseData: testPlanReleases,
        };

        dataArray.push(dataObject);
      });

    return handleADORequestData(dataArray);
  };

  const cleanUpSelectedFieldsValues = (cleanProjectValue) => {
    if (cleanProjectValue) {
      setSelectedProjectValue([]);
    }

    setSelectedAreaFieldValue([]);
    setSelectedAreaValues([]);
    setSelectedWorkItemTypeValue([]);
    setSelectedTargetReleaseValue([]);
    setSelectedReleaseValues([]);
    setSelectedReleaseFoundValue([]);
    setSelectedEnvironmentValue([]);
    setSelectedProductValue([]);

    setProgramFieldOptions([]);
    setProgramWorkItemTypeOptions([]);
    setAreaValuesOptions([]);
    setReleasesValuesOptions([]);
  };

  const getProgramName = (id) => {
    let program;

    if (id) {
      program = projectTypes.find((project) => project.projectTypeId == id);
    } else {
      let selectedProgram = selectedProjectTypeId;

      if (selectedProgram == "All") {
        selectedProgram = currentSelectedProjectTypeId;
      }

      program = projectTypes.find((project) => {
        if (project.projectTypeId == selectedProgram) {
          return project;
        }
      });
    }

    return program.projectTypeName;
  };

  const checkProgramName = () => {
    let programObject;
    let selectedProgram = currentSelectedProjectTypeId;

    if (programId) {
      let program = projectTypes.find(
        (project) => project.projectTypeId == programId
      )?.projectTypeName;

      return program;
    }

    if (!selectedProgram) {
      selectedProgram = selectedProjectTypeId;
    }

    selectedProgram = selectedProgram == "All" ? 1 : selectedProgram;

    programObject = projectTypes.find((project) => {
      if (project.projectTypeId == selectedProgram) {
        return project;
      }
    });

    return programObject?.projectTypeName;
  };

  const checkEmptyValues = (values) => {
    if (values && values.length) {
      let data = Array.isArray(values[0]) ? values[0] : values;
      let filteredEmptyValues = data.filter(
        (value) => value.fieldName !== null
      );

      return filteredEmptyValues;
    }
  };

  const deleteDuplicates = (arrayOfOptions) => {
    const objectsWithNoDuplicates = [];
    const seenFields = new Set();

    arrayOfOptions.forEach((option) => {
      const key = `${option.fieldName}_${option.program}`;
      if (!seenFields.has(key)) {
        objectsWithNoDuplicates.push(option);
        seenFields.add(key);
      }
    });

    return objectsWithNoDuplicates;
  };

  const findRepeatedLabelsWithDifferentProgram = (arrayOfOptions) => {
    const differentFields = {};

    arrayOfOptions.forEach((option) => {
      if (!differentFields[option.fieldName]) {
        differentFields[option.fieldName] = new Set();
      }
      differentFields[option.fieldName].add(option.program);
    });

    const fieldsWithDifferences = [];

    for (const fieldName in differentFields) {
      const programs = Array.from(differentFields[fieldName]);
      if (programs.length > 1) {
        fieldsWithDifferences.push(fieldName);
      }
    }

    return fieldsWithDifferences;
  };

  const formatOptions = (arr, isNotEditing) => {
    const tempArr = [];
    if (!arr || arr.length < 1) return tempArr;
    if (isNotEditing) {
      if (arr[0]?.value) return arr;
      if (typeof arr[0].fieldName == "object") {
        arr.map((item) => {
          tempArr.push({ value: item.fieldName[0], label: item.fieldName[0] });
        });
      } else {
        arr.map((item) => {
          tempArr.push({ value: item.fieldName, label: item.fieldName });
        });
      }
    } else {
      arr.map((item) => {
        tempArr.push({ value: item.fieldName, label: item.fieldName });
      });
    }
    return tempArr;
  };

  const addProjectAppendix = (values) => {
    if (!values) {
      return;
    }

    let newUpdatedValues = [...values];

    newUpdatedValues.map((option) => {
      if (option.fieldName.includes("- [")) {
        return;
      }

      let value = option.fieldName;
      let programString = option.program;

      option.fieldName = `${value} - [${programString}]`;
    });

    return newUpdatedValues;
  };

  const filterOptions = (filter, arrToFilter, obj = false) => {
    const tempFilter = [];

    if (obj) {
      filter.map((item) => tempFilter.push(item.program));
      filter = tempFilter;
    }

    if (!arrToFilter) {
      return;
    }

    return arrToFilter.filter((item) => filter.includes(item.program));
  };

  return (
    <div id="ado-info-tool-container">
      <ErrorMessage errorMessage={null} />
      <div className="row mb-4">
        <div className="col md-6">
          <label
            htmlFor="testReleases"
            className="form-label fs-7 fw-bold"
            style={{ marginBottom: "16px" }}
          >
            <span style={isAllPopulated ? {} : { color: "red" }}>* </span>
            ADO Defect Management:
          </label>
          {loading && (
            <div
              className="spinner-border text-secondary spinner-border-sm"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
          <div
            className="col-6"
            style={
              loading
                ? {
                    pointerEvents: "none",
                    opacity: "0.4",
                  }
                : {}
            }
          >
            <ul className="list-group input-group-sm">
              <li className="list-group-item">
                <div
                  className="input-group input-group-sm"
                  style={{ flexWrap: "nowrap" }}
                >
                  <span className="input-group-text" id="basic-addon3">
                    ADO Project:
                  </span>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <Multiselect
                      id="ado-project-values"
                      showArrow
                      options={projectOptions}
                      selectedValues={selectedProjectValue}
                      showCheckbox={true}
                      placeholder="Select ADO Project(s)"
                      keepSearchTerm={true}
                      hidePlaceholder={true}
                      avoidHighlightFirstOption={true}
                      isObject={false}
                      disable={loading || disabled}
                      onSelect={(values) => {
                        setSelectedProjectValue(values);
                        handleProjectFieldsRequest(values);
                      }}
                      onRemove={(values) => {
                        setSelectedProjectValue(values);
                        handleProjectFieldsRequest(values);
                        setSelectedAreaFieldValue(
                          filterOptions(values, selectedAreaFieldValue)
                        );

                        if (!values.length) {
                          handleADORequestData([]);
                          cleanUpSelectedFieldsValues(false);
                        }
                      }}
                      style={{
                        inputField: {
                          margin: "0px",
                          padding: "0px",
                          textDecoration: "none",
                        },
                        multiselectContainer: { margin: "0px", padding: "0px" },
                        searchBox: { margin: "0px", padding: "3px" },
                      }}
                    />
                  </div>
                </div>
              </li>
              <li className="list-group-item">
                <div
                  className="input-group input-group-sm"
                  style={{ flexWrap: "nowrap", margin: "5px 0" }}
                >
                  <span className="input-group-text" id="basic-addon3">
                    ADO Area Field:
                  </span>
                  <div
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      paddingBlock: "5px",
                    }}
                  >
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      disableCloseOnSelect
                      disabled={
                        loading ||
                        disabled ||
                        (selectedProjectValue &&
                          selectedProjectValue.length <= 0)
                      }
                      value={selectedAreaFieldValue || {}}
                      options={programFieldOptions || []}
                      groupBy={(option) => option.program}
                      getOptionLabel={(option) => option.fieldName}
                      onChange={(_, value) => {
                        setSelectedAreaValues(
                          filterOptions(value, selectedAreaValues, true)
                        );
                        setSelectedAreaFieldValue(value);
                      }}
                      sx={{
                        "& .MuiInputBase-root .MuiAutocomplete-tag": {
                          background: "#0096fb",
                          color: "#ffffff",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#ffffff",
                          fontSize: "16px",
                        },
                        "& .MuiFormLabel-root": {
                          fontSize: "14px",
                        },
                        "& .MuiChip-deleteIcon": {
                          color: "#ffffff!important",
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select ADO Area Field(s)"
                          size="small"
                          sx={{ width: "100%" }}
                        />
                      )}
                      renderTags={(selectedOptions, getTagProps) =>
                        selectedOptions.map((option, index) => (
                          <Tooltip
                            title={option.fieldName}
                            arrow
                            key={option.fieldName}
                          >
                            <Chip
                              label={option.fieldName}
                              {...getTagProps({ index })}
                              sx={{
                                backgroundColor: "#0096fb",
                                color: "#ffffff",
                              }}
                            />
                          </Tooltip>
                        ))
                      }
                      renderOption={(props, option) => (
                        <Tooltip title={option.fieldName} arrow>
                          <MenuItem
                            dense
                            disabled={
                              selectedAreaFieldValue.some(
                                (el) => el.program === option.program
                              )
                                ? true
                                : false
                            }
                            sx={{ fontSize: "14px" }}
                            {...props}
                          >
                            {option.fieldName}
                          </MenuItem>
                        </Tooltip>
                      )}
                    />
                  </div>
                </div>
                <div
                  className="input-group input-group-sm"
                  style={{ flexWrap: "nowrap", margin: "5px 0" }}
                >
                  <span className="input-group-text" id="basic-addon3">
                    Area Values(s):
                  </span>
                  <div
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      paddingBlock: "5px",
                    }}
                  >
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      disableCloseOnSelect
                      disabled={
                        loading ||
                        disabled ||
                        (selectedProjectValue &&
                          selectedProjectValue.length <= 0)
                      }
                      value={selectedAreaValues || []}
                      options={areaValuesOptions || []}
                      noOptionsText={"There are no options available."}
                      groupBy={(option) => option.program}
                      getOptionLabel={(option) => option.fieldName}
                      onChange={(_, value) => {
                        setSelectedAreaValues(value);
                      }}
                      sx={{
                        "& .MuiInputBase-root .MuiAutocomplete-tag": {
                          background: "#0096fb",
                          color: "#ffffff",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#ffffff",
                          fontSize: "16px",
                        },
                        "& .MuiFormLabel-root": {
                          fontSize: "14px",
                        },
                        "& .MuiChip-deleteIcon": {
                          color: "#ffffff!important",
                        },
                        maxWidth: "100%",
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select ADO Area Value(s)"
                          size="small"
                          sx={{ width: "100%" }}
                        />
                      )}
                      renderTags={(selectedOptions, getTagProps) =>
                        selectedOptions.map((option, index) => (
                          <Tooltip
                            title={option.fieldName}
                            arrow
                            key={option.fieldName}
                          >
                            <Chip
                              label={option.fieldName}
                              {...getTagProps({ index })}
                              sx={{
                                backgroundColor: "#0096fb",
                                color: "#ffffff",

                                display: "flex",
                                justifyContent: "space-between",
                                overflow: "hidden",
                              }}
                            />
                          </Tooltip>
                        ))
                      }
                      renderOption={(props, option) => (
                        <Tooltip title={option.fieldName} arrow>
                          <MenuItem dense sx={{ fontSize: "14px" }} {...props}>
                            {option.fieldName}
                          </MenuItem>
                        </Tooltip>
                      )}
                    />
                  </div>
                </div>
              </li>
              <li className="list-group-item">
                <div className="input-group input-group-sm">
                  <div
                    className="input-group input-group-sm"
                    style={{ flexWrap: "nowrap", margin: "5px 0" }}
                  >
                    <span className="input-group-text" id="basic-addon3">
                      ADO Work Item Type:
                    </span>
                    <div
                      style={{
                        width: "100%",
                        overflow: "hidden",
                        paddingBlock: "5px",
                      }}
                    >
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        disableCloseOnSelect
                        disabled={
                          loading ||
                          disabled ||
                          (selectedProjectValue &&
                            selectedProjectValue.length <= 0)
                        }
                        value={selectedWorkItemTypeValue || []}
                        options={programWorkItemTypeOptions || []}
                        groupBy={(option) => option.program}
                        getOptionLabel={(option) => option.fieldName}
                        onChange={(_, value) => {
                          setSelectedTargetReleaseValue(
                            filterOptions(
                              value,
                              selectedTargetReleaseValue,
                              true
                            )
                          );
                          setSelectedWorkItemTypeValue(value);
                        }}
                        sx={{
                          "& .MuiInputBase-root .MuiAutocomplete-tag": {
                            background: "#0096fb",
                            color: "#ffffff",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#ffffff",
                            fontSize: "16px",
                          },
                          "& .MuiFormLabel-root": {
                            fontSize: "14px",
                          },
                          "& .MuiChip-deleteIcon": {
                            color: "#ffffff!important",
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select ADO Work Item Type Value(s)"
                            size="small"
                            sx={{ width: "100%" }}
                          />
                        )}
                        renderTags={(selectedOptions, getTagProps) =>
                          selectedOptions.map((option, index) => (
                            <Tooltip
                              title={option.fieldName}
                              arrow
                              key={option.fieldName}
                            >
                              <Chip
                                label={option.fieldName}
                                {...getTagProps({ index })}
                                sx={{
                                  backgroundColor: "#0096fb",
                                  color: "#ffffff",
                                }}
                              />
                            </Tooltip>
                          ))
                        }
                        renderOption={(props, option) => (
                          <Tooltip title={option.fieldName} arrow>
                            <MenuItem
                              dense
                              disabled={
                                selectedWorkItemTypeValue?.some(
                                  (el) => el.program === option.program
                                )
                                  ? true
                                  : false
                              }
                              sx={{ fontSize: "14px" }}
                              {...props}
                            >
                              {option.fieldName}
                            </MenuItem>
                          </Tooltip>
                        )}
                      />
                    </div>
                  </div>
                  <div
                    className="input-group input-group-sm"
                    style={{ flexWrap: "nowrap", margin: "5px 0" }}
                  >
                    <span className="input-group-text" id="basic-addon3">
                      Target Release Field:
                    </span>
                    <div
                      style={{
                        width: "100%",
                        overflow: "hidden",
                        paddingBlock: "5px",
                      }}
                    >
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        disableCloseOnSelect
                        disabled={
                          loading ||
                          disabled ||
                          (selectedProjectValue &&
                            selectedProjectValue.length <= 0)
                        }
                        value={selectedTargetReleaseValue || []}
                        options={programFieldOptions || []}
                        groupBy={(option) => option.program}
                        getOptionLabel={(option) => option.fieldName}
                        onChange={(_, value) => {
                          setSelectedReleaseValues(
                            filterOptions(value, selectedReleaseValues, true)
                          );
                          setSelectedTargetReleaseValue(value);
                        }}
                        sx={{
                          "& .MuiInputBase-root .MuiAutocomplete-tag": {
                            background: "#0096fb",
                            color: "#ffffff",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#ffffff",
                            fontSize: "16px",
                          },
                          "& .MuiFormLabel-root": {
                            fontSize: "14px",
                          },
                          "& .MuiChip-deleteIcon": {
                            color: "#ffffff!important",
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Target Release Field Value(s)"
                            size="small"
                            sx={{ width: "100%" }}
                          />
                        )}
                        renderTags={(selectedOptions, getTagProps) =>
                          selectedOptions.map((option, index) => (
                            <Tooltip
                              title={option.fieldName}
                              arrow
                              key={option.fieldName}
                            >
                              <Chip
                                label={option.fieldName}
                                {...getTagProps({ index })}
                                sx={{
                                  backgroundColor: "#0096fb",
                                  color: "#ffffff",
                                }}
                              />
                            </Tooltip>
                          ))
                        }
                        renderOption={(props, option) => (
                          <Tooltip title={option.fieldName} arrow>
                            <MenuItem
                              dense
                              disabled={
                                selectedTargetReleaseValue?.some(
                                  (el) => el.program === option.program
                                )
                                  ? true
                                  : false
                              }
                              sx={{ fontSize: "14px" }}
                              {...props}
                            >
                              {option.fieldName}
                            </MenuItem>
                          </Tooltip>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="input-group input-group-sm"
                  style={{ flexWrap: "nowrap" }}
                >
                  <span className="input-group-text" id="basic-addon3">
                    Releases:
                  </span>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <CreatableSelect
                      isMulti
                      placeholder="Select ADO Releases(s)"
                      isDisabled={
                        loading ||
                        disabled ||
                        (selectedProjectValue &&
                          selectedProjectValue.length <= 0)
                      }
                      options={
                        formatOptions(releasesValuesOptions, false) || []
                      }
                      value={formatOptions(selectedReleaseValues, true)}
                      onChange={(value) => {
                        setSelectedReleaseValues(value);
                      }}
                      className={
                        loading ||
                        disabled ||
                        (selectedProjectValue &&
                          selectedProjectValue.length <= 0)
                          ? "Creatable-component_off"
                          : "Creatable-component"
                      }
                    />
                  </div>
                </div>
              </li>
              <li className="list-group-item">
                <div
                  className="input-group input-group-sm"
                  style={{ flexWrap: "nowrap" }}
                >
                  <span className="input-group-text" id="basic-addon3">
                    Release Found Field:
                  </span>
                  <div
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      paddingBlock: "5px",
                    }}
                  >
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      disableCloseOnSelect
                      disabled={
                        loading ||
                        disabled ||
                        (selectedProjectValue &&
                          selectedProjectValue.length <= 0)
                      }
                      value={selectedReleaseFoundValue || []}
                      options={programFieldOptions || []}
                      groupBy={(option) => option.program}
                      getOptionLabel={(option) => option.fieldName}
                      onChange={(_, value) => {
                        setSelectedReleaseFoundValue(value);
                      }}
                      sx={{
                        "& .MuiInputBase-root .MuiAutocomplete-tag": {
                          background: "#0096fb",
                          color: "#ffffff",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#ffffff",
                          fontSize: "16px",
                        },
                        "& .MuiFormLabel-root": {
                          fontSize: "14px",
                        },
                        "& .MuiChip-deleteIcon": {
                          color: "#ffffff!important",
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Release Found Value(s)"
                          size="small"
                          sx={{ width: "100%" }}
                        />
                      )}
                      renderTags={(selectedOptions, getTagProps) =>
                        selectedOptions.map((option, index) => (
                          <Tooltip
                            title={option.fieldName}
                            arrow
                            key={option.fieldName}
                          >
                            <Chip
                              label={option.fieldName}
                              {...getTagProps({ index })}
                              sx={{
                                backgroundColor: "#0096fb",
                                color: "#ffffff",
                              }}
                            />
                          </Tooltip>
                        ))
                      }
                      renderOption={(props, option) => (
                        <Tooltip title={option.fieldName} arrow>
                          <MenuItem
                            dense
                            disabled={
                              selectedReleaseFoundValue?.some(
                                (el) => el.program === option.program
                              )
                                ? true
                                : false
                            }
                            sx={{ fontSize: "14px" }}
                            {...props}
                          >
                            {option.fieldName}
                          </MenuItem>
                        </Tooltip>
                      )}
                    />
                  </div>
                </div>
              </li>
              <li className="list-group-item">
                <div
                  className="input-group input-group-sm"
                  style={{ flexWrap: "nowrap" }}
                >
                  <span className="input-group-text" id="basic-addon3">
                    Environment Field:
                  </span>
                  <div
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      paddingBlock: "5px",
                    }}
                  >
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      disableCloseOnSelect
                      disabled={
                        loading ||
                        disabled ||
                        (selectedProjectValue &&
                          selectedProjectValue.length <= 0)
                      }
                      value={selectedEnvironmentValue || []}
                      options={programFieldOptions || []}
                      groupBy={(option) => option.program}
                      getOptionLabel={(option) => option.fieldName}
                      onChange={(_, value) => {
                        setSelectedEnvironmentValue(value);
                      }}
                      sx={{
                        "& .MuiInputBase-root .MuiAutocomplete-tag": {
                          background: "#0096fb",
                          color: "#ffffff",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#ffffff",
                          fontSize: "16px",
                        },
                        "& .MuiFormLabel-root": {
                          fontSize: "14px",
                        },
                        "& .MuiChip-deleteIcon": {
                          color: "#ffffff!important",
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Environment Value(s)"
                          size="small"
                          sx={{ width: "100%" }}
                        />
                      )}
                      renderTags={(selectedOptions, getTagProps) =>
                        selectedOptions.map((option, index) => (
                          <Tooltip
                            title={option.fieldName}
                            arrow
                            key={option.fieldName}
                          >
                            <Chip
                              label={option.fieldName}
                              {...getTagProps({ index })}
                              sx={{
                                backgroundColor: "#0096fb",
                                color: "#ffffff",
                              }}
                            />
                          </Tooltip>
                        ))
                      }
                      renderOption={(props, option) => (
                        <Tooltip title={option.fieldName} arrow>
                          <MenuItem
                            dense
                            disabled={
                              selectedEnvironmentValue?.some(
                                (el) => el.program === option.program
                              )
                                ? true
                                : false
                            }
                            sx={{ fontSize: "14px" }}
                            {...props}
                          >
                            {option.fieldName}
                          </MenuItem>
                        </Tooltip>
                      )}
                    />
                  </div>
                </div>
              </li>
              <li className="list-group-item">
                <div
                  className="input-group input-group-sm"
                  style={{ flexWrap: "nowrap" }}
                >
                  <span className="input-group-text" id="basic-addon3">
                    Product Field:
                  </span>
                  <div style={{ width: "100%" }}>
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      disableCloseOnSelect
                      disabled={
                        loading ||
                        disabled ||
                        (selectedProjectValue &&
                          selectedProjectValue.length <= 0)
                      }
                      value={selectedProductValue || []}
                      options={programFieldOptions || []}
                      groupBy={(option) => option.program}
                      getOptionLabel={(option) => option.fieldName}
                      onChange={(_, value) => {
                        setSelectedProductValue(value);
                      }}
                      sx={{
                        "& .MuiInputBase-root .MuiAutocomplete-tag": {
                          background: "#0096fb",
                          color: "#ffffff",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#ffffff",
                          fontSize: "16px",
                        },
                        "& .MuiFormLabel-root": {
                          fontSize: "14px",
                        },
                        "& .MuiChip-deleteIcon": {
                          color: "#ffffff!important",
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Product Value(s)"
                          size="small"
                          sx={{ width: "100%" }}
                        />
                      )}
                      renderTags={(selectedOptions, getTagProps) =>
                        selectedOptions.map((option, index) => (
                          <Tooltip
                            title={option.fieldName}
                            arrow
                            key={option.fieldName}
                          >
                            <Chip
                              label={option.fieldName}
                              {...getTagProps({ index })}
                              sx={{
                                backgroundColor: "#0096fb",
                                color: "#ffffff",
                              }}
                            />
                          </Tooltip>
                        ))
                      }
                      renderOption={(props, option) => (
                        <Tooltip title={option.fieldName} arrow>
                          <MenuItem
                            dense
                            disabled={
                              selectedProductValue?.some(
                                (el) => el.program === option.program
                              )
                                ? true
                                : false
                            }
                            sx={{ fontSize: "14px" }}
                            {...props}
                          >
                            {option.fieldName}
                          </MenuItem>
                        </Tooltip>
                      )}
                    />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ADOInfo;
