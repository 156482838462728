import React, { useState, useEffect, useRef } from "react";
import CommonService from "../../services/ScoreCardService";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProgressBar } from "primereact/progressbar";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import "primereact/resources/themes/saga-blue/theme.css"; // or any other theme
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const AdminComponent = () => {
  const [token, setToken] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [projects, setProjects] = useState([]);
  const [units, setUnits] = useState([]);
  const [smeusers, setSmeusers] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [allUnitUsers, setAllUnitUsers] = useState([]);
  const [criteriaItemList, setCriteriaItemList] = useState([]);
  const [assessment, setAssessment] = useState({
    year: undefined,
    status: undefined,
    owners: [],
  });
  const [addEditAssessment, setAddEditAssessment] = useState(false);
  const [showSetupCriteria, setShowSetupCriteria] = useState(false);
  const [minimumDate, setMinimumDate] = useState(new Date());
  const [quarters, setQuarters] = useState([
    { label: "Q1", value: "1" },
    { label: "Q2", value: "2" },
    { label: "Q3", value: "3" },
    { label: "Q4", value: "4" },
  ]);
  const [assessmentTypes, setAssessmentTypes] = useState([
    { name: "Self Assessment", code: "1" },
    { name: "Scorecard", code: "2" },
  ]);
  const [assessmentAnswerTypes, setAssessmentAnswerTypes] = useState([
    { name: "Binary", code: "1" },
    { name: "Level", code: "2" },
  ]);
  const toast = useRef(null);

  useEffect(() => {
    getAllTemplates();
    getAllDepartments();
    getAllBussinessUnits();
    getAllUsersByRole(token, "SME");
    getAllUsers();
    getAllAssessments();
  }, []);

  const getAllTemplates = () => {
    CommonService.getAllTemplates().then((data) => setTemplateList(data));
  };

  const getAllDepartments = () => {
    CommonService.getAllDepartments().then((data) => setDepartments(data));
  };

  const getAllBussinessUnits = () => {
    CommonService.getAllBussinessUnits().then((data) => setUnits(data));
  };

  const getAllUsersByRole = (token, role) => {
    CommonService.getAllUsersByRole(token, role).then((data) =>
      setSmeusers(data)
    );
  };

  const getAllUsers = () => {
    CommonService.getAllUsers().then((data) => {
      setAllUnitUsers((prev) => [
        ...prev,
        ...data,
        { full_name: "None", email: "none" },
      ]);
    });
  };

  const getAllAssessments = () => {
    CommonService.getAllAssessments().then((data) => {
      let assessmentList = data.map((element) => ({
        key: element.id,
        data: {
          assesmentname: element.assesmentname,
          program: element.program,
          project: element.project,
          assignedto: element.assignedto,
          createddate: element.createddate
            ? converDateToMMDDYYYY(element.createddate)
            : "--",
          startdate: element.startdate
            ? converDateToMMDDYYYY(element.startdate)
            : "--",
          enddate: element.enddate
            ? converDateToMMDDYYYY(element.enddate)
            : "--",
          expirydate: converDateToMMDDYYYY(element.expirydate),
          assesmentType: element.assesmentType,
          unit: element.unit,
          status: element.status,
          activity: element.activity,
          owners: element.owners,
          templateId: element.templateId,
          answerType: element.answerType,
          assignedUser: element.assignedUser,
          year: element.year,
          quarter: element.quarter,
          department: element.department,
        },
      }));
      assessmentList.sort(
        (a, b) =>
          new Date(b.data.createddate).getTime() -
          new Date(a.data.createddate).getTime()
      );
      setTableData(assessmentList);
    });
  };

  const converDateToMMDDYYYY = (pDate) => {
    let date = new Date();
    date.setMonth(Number(pDate.split("-")[1]) - 1);
    date.setDate(Number(pDate.split("-")[2]));
    date.setFullYear(Number(pDate.split("-")[0]));
    let convertedDate =
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)) +
      "/" +
      (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
      "/" +
      date.getFullYear();
    return convertedDate;
  };

  const showAddEditAssesment = (type, assessment) => {
    setAddEditAssessment(true);
    const today = new Date();
    setShowSetupCriteria(false);
    if (type === "add") {
      setAssessment({
        status: "Not Started",
        createddate: today.toISOString().split("T")[0],
        owners: [],
      });
    } else {
      getPrograms(token, assessment.data.department);
      getProjects(token, assessment.data.program);
      setAssessment(assessment.data);
    }
  };

  const editAssessment = (id) => {
    CommonService.getAssessmentById(id).then((data) => {
      showAddEditAssesment("edit", data);
    });
  };

  const getPrograms = (token, deptCode) => {
    CommonService.getAllProgrambyDeptCode(token, deptCode).then((data) =>
      setPrograms(data)
    );
  };

  const getProjects = (token, programCode) => {
    CommonService.getAllProjectsByProgramCode(token, programCode).then((data) =>
      setProjects(data)
    );
  };

  const nextToSetupCriteria = () => {
    setShowSetupCriteria(true);
  };

  const createUpdateAssessment = () => {
    udpateDateFormat();
    assessment.year = assessment.year
      ? new Date(assessment.year.toString()).getFullYear().toString()
      : new Date().getFullYear().toString();
    setAddEditAssessment(false);
    if (assessment.id) {
      CommonService.updateAssessment(assessment).then((data) => {
        setTableData([]);
        getAllAssessments();
      });
    } else {
      let unittoSave = units.find((unit) => unit.id === assessment.unit);
      let prpjecttoSave = projects.find(
        (project) => project.name === assessment.project
      );
      assessment.unit = unittoSave;
      assessment.project = prpjecttoSave;
      CommonService.addNewAssessment(assessment).then((data) => {
        setAddEditAssessment(false);
        setTableData([]);
        getAllAssessments();
      });
    }
  };

  const udpateDateFormat = () => {
    if (assessment.expirydate) {
      assessment.expirydate = new Date(assessment.expirydate.toString())
        .toISOString()
        .split("T")[0];
    }
  };

  const getCriteriaList = (templateId) => {
    CommonService.getAllCriteriaByTemplateId(token, templateId).then((data) => {
      let criteriaItemList = [];
      data.forEach((element) => {
        let moduleData = {
          id: element.idScoring_Module,
          name: element.module_name,
          weight: "",
          target_level: "",
          type: "Area",
        };
        let childrenModule = [];
        element.subModuleList.forEach((item) => {
          let childrenSubModule = [];
          item.ciList.forEach((criteria) => {
            childrenSubModule.push({
              key: criteria.id_criteria_info,
              data: {
                id: criteria.id_criteria_info,
                name: criteria.criteria_description,
                weight: criteria.weight,
                target_level: criteria.target_level,
                type: "Criteria",
              },
            });
          });
          childrenModule.push({
            key: item.id_sub_modules,
            data: {
              id: item.id_sub_modules,
              name: item.sub_module_name,
              weight: "",
              target_level: "",
              type: "Sub Area",
            },
            children: childrenSubModule,
          });
        });
        criteriaItemList.push({ data: moduleData, children: childrenModule });
      });
      setCriteriaItemList(criteriaItemList);
    });
  };

  return (
    <div>
      <Toast ref={toast} />
      <ConfirmDialog />
      <div style={{ minWidth: "10%", margin: "0px 0px 30px" }}></div>
      <Button
        label="Add New Assessment"
        className="btn btn-primary"
        style={{ minWidth: "10%", margin: "0px 0px 30px" }}
        onClick={() => showAddEditAssesment("add", assessment)}
      />
      <TreeTable
        value={tableData}
        className="p-treetable-striped custom-treetable"
        scrollable
        paginator
        rows={7}
      >
        <Column
          field="assesmentname"
          header="Assessment Name"
          body={(rowData) => (
            <button
              onClick={() => showAddEditAssesment("edit", rowData)}
              style={{
                background: "none",
                border: "none",
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {rowData.data.assesmentname}
            </button>
          )}
          sortable
          expander
        />
        <Column field="program" header="Program" sortable />
        <Column field="project.name" header="Project" sortable />
        <Column field="assignedUser" header="Assigned" sortable />
        <Column field="createddate" header="Created" sortable />
        <Column field="startdate" header="Start Date" sortable />
        <Column field="enddate" header="Finished" />
        <Column field="expirydate" header="Expiry" />
        <Column field="assesmentType" header="Type" />
        <Column field="year" header="Year" sortable />
        <Column field="status" header="Status" sortable />
        <Column
          field="activity"
          header="Activity"
          body={(rowData) => <ProgressBar value={rowData.data.activity} />}
        />
      </TreeTable>
      <Dialog
        header="Add or Edit Assessment"
        visible={addEditAssessment}
        onHide={() => setAddEditAssessment(false)}
        maximizable
        modal
        className="p-fluid custom-dialog"
        style={{
          width: "70vw",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
        }} // Adjusted width and added styles
      >
        <div style={{ minHeight: "500px", padding: "20px" }}>
          {!showSetupCriteria ? (
            <div className="p-grid p-fluid">
              <div
                className="p-field p-col-12 p-md-6 p-lg-4"
                style={{ display: "flex", alignItems: "center" }}
              >
                <label
                  htmlFor="assessmentName"
                  style={{
                    marginRight: "10px",
                    fontWeight: "bold",
                    width: "150px",
                  }}
                >
                  Assessment Name
                </label>
                <input
                  id="assessmentName"
                  className="p-element p-inputwrapper ng-untouched ng-pristine ng-valid p-inputtext p-component"
                  value={assessment.assesmentname || ""}
                  onChange={(e) =>
                    setAssessment({
                      ...assessment,
                      assesmentname: e.target.value,
                    })
                  }
                  disabled={assessment.status !== "Not Started"}
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #ced4da",
                    flex: 1,
                  }}
                />
              </div>
              <div
                className="p-field p-col-12 p-md-6 p-lg-4"
                style={{ display: "flex", alignItems: "center" }}
              >
                <label
                  htmlFor="department"
                  style={{
                    marginRight: "10px",
                    fontWeight: "bold",
                    width: "150px",
                  }}
                >
                  Department Name
                </label>
                <Dropdown
                  id="department"
                  className="p-element p-inputwrapper ng-untouched ng-pristine ng-valid"
                  value={departments.find(
                    (dept) => dept.name === assessment.department
                  )}
                  options={departments}
                  onChange={(e) => {
                    setAssessment({ ...assessment, department: e.value.name });
                    getPrograms(token, e.value.name);
                  }}
                  optionLabel="name"
                  placeholder="Select a Department"
                  disabled={assessment.status !== "Not Started"}
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #ced4da",
                    flex: 1,
                  }}
                />
              </div>
              <div
                className="p-field p-col-12 p-md-6 p-lg-4"
                style={{ display: "flex", alignItems: "center" }}
              >
                <label
                  htmlFor="program"
                  style={{
                    marginRight: "10px",
                    fontWeight: "bold",
                    width: "150px",
                  }}
                >
                  Program Name
                </label>
                <Dropdown
                  id="program"
                  value={programs.find(
                    (prog) => prog.name === assessment.program
                  )}
                  options={programs}
                  onChange={(e) => {
                    setAssessment({ ...assessment, program: e.value.name });
                    getProjects(token, e.value.name);
                  }}
                  optionLabel="name"
                  placeholder="Select a Program"
                  disabled={assessment.status !== "Not Started"}
                  className="p-inputtext p-component"
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #ced4da",
                    flex: 1,
                  }}
                />
              </div>
              <div
                className="p-field p-col-12 p-md-6 p-lg-4"
                style={{ display: "flex", alignItems: "center" }}
              >
                <label
                  htmlFor="project"
                  style={{
                    marginRight: "10px",
                    fontWeight: "bold",
                    width: "150px",
                  }}
                >
                  Project/Product
                </label>
                <Dropdown
                  id="project"
                  value={projects.find(
                    (proj) => proj.name === assessment.project
                  )}
                  options={projects}
                  onChange={(e) =>
                    setAssessment({ ...assessment, project: e.value.name })
                  }
                  optionLabel="name"
                  placeholder="Select a Project / Product"
                  disabled={assessment.status !== "Not Started"}
                  className="p-inputtext p-component"
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #ced4da",
                    flex: 1,
                  }}
                />
              </div>
              <div
                className="p-field p-col-12 p-md-6 p-lg-4"
                style={{ display: "flex", alignItems: "center" }}
              >
                <label
                  htmlFor="expirydate"
                  style={{
                    marginRight: "10px",
                    fontWeight: "bold",
                    width: "150px",
                  }}
                >
                  Assessment Expiry
                </label>
                <Calendar
                  id="expirydate"
                  value={
                    assessment.expirydate
                      ? new Date(assessment.expirydate.toString())
                      : undefined
                  }
                  onChange={(e) =>
                    setAssessment({
                      ...assessment,
                      expirydate: e.value ? new Date(e.value) : undefined,
                    })
                  }
                  minDate={minimumDate}
                  dateFormat="mm/dd/yy"
                  disabled={assessment.status !== "Not Started"}
                  className="p-inputtext p-component"
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #ced4da",
                    flex: 1,
                  }}
                />
              </div>
              <div
                className="p-field p-col-12 p-md-6 p-lg-4"
                style={{ display: "flex", alignItems: "center" }}
              >
                <label
                  htmlFor="unit"
                  style={{
                    marginRight: "10px",
                    fontWeight: "bold",
                    width: "150px",
                  }}
                >
                  Assessment Team
                </label>
                <Dropdown
                  id="unit"
                  value={units.find((unit) => unit.id === assessment.unit)}
                  options={units}
                  onChange={(e) =>
                    setAssessment({ ...assessment, unit: e.value.id })
                  }
                  optionLabel="name"
                  placeholder="Select a Assessment Team"
                  disabled={assessment.status !== "Not Started"}
                  className="p-inputtext p-component"
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #ced4da",
                    flex: 1,
                  }}
                />
              </div>
              <div
                className="p-field p-col-12 p-md-6 p-lg-4"
                style={{ display: "flex", alignItems: "center" }}
              >
                <label
                  htmlFor="year"
                  style={{
                    marginRight: "10px",
                    fontWeight: "bold",
                    width: "150px",
                  }}
                >
                  Assessment Year
                </label>
                <Calendar
                  id="year"
                  value={
                    assessment.year
                      ? new Date(assessment.year.toString())
                      : undefined
                  }
                  onChange={(e) =>
                    setAssessment({
                      ...assessment,
                      year: e.value
                        ? e.value.getFullYear().toString()
                        : undefined,
                    })
                  }
                  view="year"
                  dateFormat="yy"
                  yearNavigator
                  yearRange="2000:2030"
                  readOnlyInput
                  placeholder="Select Assessment Year"
                  disabled={assessment.status !== "Not Started"}
                  className="p-inputtext p-component"
                  style={{
                    marginRight: "20px",
                    borderRadius: "4px",
                    border: "1px solid #ced4da",
                    flex: 1,
                  }}
                />
                <label
                  htmlFor="quarter"
                  style={{
                    marginRight: "10px",
                    fontWeight: "bold",
                    width: "150px",
                  }}
                >
                  Assessment Quarter
                </label>
                <Dropdown
                  id="quarter"
                  value={quarters.find(
                    (quarter) => quarter.value === assessment.quarter
                  )}
                  options={quarters}
                  onChange={(e) =>
                    setAssessment({ ...assessment, quarter: e.value })
                  }
                  optionLabel="label"
                  placeholder="Select a Assessment Quarter"
                  disabled={assessment.status !== "Not Started"}
                  className="p-inputtext p-component"
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #ced4da",
                    flex: 1,
                  }}
                />
              </div>
              <div
                className="p-field p-col-12 p-md-6 p-lg-4"
                style={{ display: "flex", alignItems: "center" }}
              >
                <label
                  htmlFor="assesmentType"
                  style={{
                    marginRight: "10px",
                    fontWeight: "bold",
                    width: "150px",
                  }}
                >
                  Assessment Type
                </label>
                <Dropdown
                  id="assesmentType"
                  value={assessmentTypes.find(
                    (type) => type.name === assessment.assesmentType
                  )}
                  options={assessmentTypes}
                  onChange={(e) =>
                    setAssessment({
                      ...assessment,
                      assesmentType: e.value.name,
                    })
                  }
                  optionLabel="name"
                  placeholder="Select a Assessment Type"
                  disabled={assessment.status !== "Not Started"}
                  className="p-inputtext p-component"
                  style={{
                    marginRight: "20px",
                    borderRadius: "4px",
                    border: "1px solid #ced4da",
                    flex: 1,
                  }}
                />
                <label
                  htmlFor="answerType"
                  style={{
                    marginRight: "10px",
                    fontWeight: "bold",
                    width: "150px",
                  }}
                >
                  Assessment Answer
                </label>
                <Dropdown
                  id="answerType"
                  value={assessmentAnswerTypes.find(
                    (type) => type.name === assessment.answerType
                  )}
                  options={assessmentAnswerTypes}
                  onChange={(e) =>
                    setAssessment({ ...assessment, answerType: e.value.name })
                  }
                  optionLabel="name"
                  placeholder="Select a Answer Type"
                  disabled={assessment.status !== "Not Started"}
                  className="p-inputtext p-component"
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #ced4da",
                    flex: 1,
                  }}
                />
              </div>
              <div
                className="p-field p-col-12 p-md-6 p-lg-4"
                style={{ display: "flex", alignItems: "center" }}
              >
                <label
                  htmlFor="owners"
                  style={{
                    marginRight: "10px",
                    fontWeight: "bold",
                    width: "150px",
                  }}
                >
                  Co-Owners
                </label>
                <div style={{ flex: 1 }}>
                  {smeusers.map((user) => (
                    <div
                      key={user.email}
                      className="p-field-checkbox"
                      style={{ display: "inline-block", marginRight: "10px" }}
                    >
                      <Checkbox
                        inputId={user.email}
                        value={user.email}
                        onChange={(e) => {
                          const selectedOwners = [...assessment.owners];
                          if (e.checked) {
                            selectedOwners.push(e.value);
                          } else {
                            const index = selectedOwners.indexOf(e.value);
                            if (index !== -1) {
                              selectedOwners.splice(index, 1);
                            }
                          }
                          setAssessment({
                            ...assessment,
                            owners: selectedOwners,
                          });
                        }}
                        checked={assessment.owners.includes(user.email)}
                        disabled={assessment.status !== "Not Started"}
                      />
                      <label htmlFor={user.email}>{user.full_name}</label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="p-grid p-fluid">
              <div
                className="p-field p-col-12"
                style={{ display: "flex", alignItems: "center" }}
              >
                <label
                  htmlFor="templateId"
                  style={{ fontWeight: "bold", width: "150px" }}
                >
                  Select a Template Type
                </label>
                <Dropdown
                  id="templateId"
                  value={templateList.find(
                    (template) => template.id === assessment.templateId
                  )}
                  options={templateList}
                  onChange={(e) => {
                    setAssessment({ ...assessment, templateId: e.value.id });
                    getCriteriaList(e.value.id);
                  }}
                  optionLabel="name"
                  placeholder="Select a Template Type"
                  disabled={assessment.status !== "Not Started"}
                  className="p-inputtext p-component"
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #ced4da",
                    flex: 1,
                  }}
                />
              </div>
              <TreeTable
                value={criteriaItemList}
                className="p-treetable-striped"
              >
                <Column field="name" header="Name" expander />
                <Column field="type" header="Type" />
                <Column field="target_level" header="Target Level" />
                <Column field="weight" header="Weight" />
              </TreeTable>
              <div className="p-field p-col-12">
                <label htmlFor="assignedto">Assign To</label>
                <Dropdown
                  id="assignedto"
                  value={allUnitUsers.find(
                    (user) => user.email === assessment.assignedto
                  )}
                  options={allUnitUsers}
                  onChange={(e) =>
                    setAssessment({ ...assessment, assignedto: e.value.email })
                  }
                  optionLabel="full_name"
                  placeholder="Select a User"
                  disabled={assessment.status !== "Not Started"}
                  className="p-inputtext p-component"
                  style={{ borderRadius: "4px", border: "1px solid #ced4da" }}
                />
              </div>
            </div>
          )}
        </div>
        <div
          className="p-dialog-footer"
          style={{ padding: "10px 20px", borderTop: "1px solid #dee2e6" }}
        >
          <Button
            label="Cancel"
            className="btn btn-primary"
            onClick={() => setAddEditAssessment(false)}
            style={{ marginRight: "10px" }}
          />
          {showSetupCriteria ? (
            <>
              <Button
                label="Save"
                className="btn btn-primary"
                onClick={createUpdateAssessment}
                disabled={assessment.status !== "Not Started"}
                style={{ marginRight: "10px" }}
              />
              <Button
                label="Previous"
                className="btn btn-primary"
                onClick={() => setShowSetupCriteria(false)}
              />
            </>
          ) : (
            <Button
              label="Next"
              className="btn btn-primary"
              onClick={nextToSetupCriteria}
              disabled={
                !assessment.assesmentname ||
                !assessment.program ||
                !assessment.project ||
                !assessment.expirydate ||
                !assessment.unit ||
                !assessment.assesmentType ||
                !assessment.answerType ||
                !assessment.year ||
                !assessment.quarter ||
                !assessment.department
              }
            />
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default AdminComponent;
