import React, { useState, useEffect } from "react";
import Qtest from "../../services/Qtest";
import ErrorMessage from "../../components/common/ErrorMessage";
import Loading from "../../components/common/Loading";
import useQtestInfo from "../../components/hooks/useQtestInfo";
import useProjectDetails from "../../components/hooks/useProjectDetails";
import useErrorMessage from "../../components/hooks/useErrorMessage";
import TestCasesDevelopmentModal from "../../components/common/modals/TestCasesDevelopmentModal";
import ADO from "../../services/ADO";
import Projects from "../../services/Projects";
import { Chart } from "react-google-charts";
import { useAppContext } from "../../contexts/AppContext";
import "../../components/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import { useParams } from "react-router-dom";

async function getTestCasesInfo(token, projectId) {
  try {
    const resp = await Qtest.getTestExecutionInfo(token, projectId);
    const manualVsAutomatedResp = await Qtest.getManualvsAutomated(
      token,
      projectId
    );
    const automatedTestRunByType = await Qtest.getAutomatedTestRunByType(
      token,
      projectId
    );
    const manualTestRunROI = await Qtest.getManualTestRunROI(token, projectId);
    const testExecutionFailureByType =
      await Qtest.getTestExecutionFailuresByType(token, projectId);
    const testExecutionFailureByTypeTrending =
      await Qtest.getTestExecutionFailuresByTypeTrending(token, projectId);

    return {
      ...resp,
      testManualVsAutomated: manualVsAutomatedResp,
      testAutomatedTestRunByType: automatedTestRunByType,
      testManualTestRunROI: manualTestRunROI,
      testExecutionFailureByType,
      testExecutionFailureByTypeTrending,
    };
  } catch (err) {
    return {
      message:
        "Error getting Test Cases info (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

async function getProjectInfo(token, projectId) {
  try {
    const resp = await Projects.getProjectDetails(token, projectId);
    return resp;
  } catch (err) {
    return {
      message:
        "Error Jira info (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

async function getADOChartsInfo(token, projectId, body) {
  try {
    const resp = await ADO.getADOTestExecutionChartsInfo(
      token,
      projectId,
      body
    );
    return resp;
  } catch (err) {
    return {
      message:
        "Error Jira info (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

export default function TestExecutionPage(props) {
  const { testExecutionInfo, setTestExecutionInfo } = useQtestInfo();
  const [testExecutionChurnInfo, setTestExecutionChurnInfo] = useState(null);
  const { projectDetails, setProjectDetails } = useProjectDetails();
  const { setErrorMessage, errorMessage } = useErrorMessage();
  const [loading, setLoading] = useState(true);
  const [iterations, setIterations] = useState([]);
  const [currentIteration, setCurrentIteration] = useState("Overall");
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState();
  const { currentProjectDetails } = useAppContext();

  const { projectId } = useParams();

  const currentProjectSourceOfInformation =
    currentProjectDetails.projectTestSource;
  const DataSource = {
    JIRA: "JIRA",
    ADO: "ADO",
    QTEST: "qTest",
  };
  let tokenId;

  if (props.token) {
    tokenId = props.token;
  } else {
    tokenId = window.localStorage.getItem("token");
  }

  useEffect(() => {
    const handleQtestDetails = async (e) => {
      var currentProjectId = props.currentProjectId;
      var url = window.location.href;
      let res = /project\/(\d+)/g.exec(url);

      if (!currentProjectId) {
        currentProjectId = projectId;
        props.setCurrentProjectId(currentProjectId);
      }

      if (
        currentProjectDetails &&
        Object.keys(currentProjectDetails).length === 0
      ) {
        const res = await getProjectInfo(tokenId, currentProjectId);
        setProjectDetails(res);
      }

      if (!testExecutionChurnInfo) {
        const response =
          DataSource.ADO === currentProjectSourceOfInformation
            ? null
            : await Qtest.getTestExecutionChurnInfo(tokenId, currentProjectId);
        if (response && response.message) {
          setErrorMessage(response.message);
          setTestExecutionChurnInfo(null);
        } else {
          setTestExecutionChurnInfo(response);
        }
      }

      if (
        !testExecutionInfo ||
        testExecutionInfo.projectId !== currentProjectId
      ) {
        const response =
          DataSource.ADO === currentProjectSourceOfInformation
            ? await getADOChartsInfo(tokenId, currentProjectId, projectDetails)
            : await getTestCasesInfo(tokenId, currentProjectId);
        if (response.message) {
          setErrorMessage(response.message);
          setTestExecutionInfo(null);
        } else {
          response.projectTypeId += "";
          setTestExecutionInfo(response);

          let iterations =
            DataSource.ADO === currentProjectSourceOfInformation
              ? response.manualVsAutomatedTests
              : response.testExecutionSummary;
          iterations &&
            setIterations(sortIterationsByStartDate(Object.keys(iterations)));
        }
      } else {
        if (testExecutionInfo && iterations) {
          let iterations =
            DataSource.ADO === currentProjectSourceOfInformation
              ? testExecutionInfo.manualVsAutomatedTests
              : testExecutionInfo.testExecutionSummary;
          saveIterations(Object.keys(iterations));
        }
      }
      setLoading(false);
    };
    props.changeStyle("testcases");
    handleQtestDetails();
  }, [props.currentProjectId, projectId]);

  const sortIterationsByStartDate = (iterationsList) => {
    if (projectDetails && Object.keys(projectDetails).length > 0) {
      let sorted = ["Overall"];
      projectDetails?.lpIterations.sort((a, b) =>
        a.startDate > b.startDate ? 1 : b.startDate > a.startDate ? -1 : 0
      );
      projectDetails?.lpIterations.forEach((item) => {
        if (iterationsList.indexOf(item.iterationName) >= 0) {
          sorted.push(item.iterationName);
        }
      });
      return sorted;
    }
  };

  const saveIterations = (iterationsList) => {
    let list = ["Overall"];
    if (iterationsList) {
      list = list.concat(iterationsList.filter((item) => item !== "Overall"));
    }
    list = sortIterationsByStartDate(list);
    return setIterations(list);
  };

  const TestExecutionChart = () => {
    const getTooltip = function (testCycle, status, value, percentage) {
      return (
        testCycle +
        "\n" +
        status +
        ": " +
        percentage +
        "%\nTest Cases: " +
        value
      );
    };

    const getPercentage = (number) => {
      let result = number < 1 ? Math.ceil(number) : Math.floor(number);
      return result;
    };

    const testExecutionSummary = testExecutionInfo.testExecutionSummary;

    if (
      !testExecutionSummary ||
      !testExecutionSummary[currentIteration] ||
      Object.keys(testExecutionSummary[currentIteration]).length < 1
    ) {
      return (
        <div className="form-outline mt-4" id="error-message">
          <div className="alert alert-danger" role="alert">
            Data is empty!
          </div>
        </div>
      );
    }
    const currentExecSummary = testExecutionSummary[currentIteration];
    const labels = Object.keys(currentExecSummary);

    var columns = [
      "Test Cycle",
      "Executed",
      { type: "string", role: "tooltip" },
      "Passed",
      { type: "string", role: "tooltip" },
      "Failed",
      { type: "string", role: "tooltip" },
      "Blocked",
      { type: "string", role: "tooltip" },
      "Incomplete",
      { type: "string", role: "tooltip" },
    ];

    var data = [];
    data.push(columns);

    labels.forEach(function (label) {
      let labelData = currentExecSummary[label];
      labelData = labelData ? labelData : {};
      labelData.Total = labelData.Total ? labelData.Total : 0;
      labelData.Passed = labelData?.Passed ? labelData.Passed : 0;
      labelData.Failed = labelData?.Failed ? labelData.Failed : 0;
      labelData.Blocked = labelData?.Blocked ? labelData.Blocked : 0;
      labelData.Incomplete = labelData?.Incomplete ? labelData.Incomplete : 0;

      const totalTestRuns = labelData.Total;
      let executed = labelData.Passed + labelData.Failed;

      const rowValue = [
        label,
        Math.round((executed / totalTestRuns) * 100),
        getTooltip(
          label,
          "Executed",
          executed,
          Math.round((executed / totalTestRuns) * 100)
        ),
      ];

      let percentageValue = getPercentage((labelData.Passed / executed) * 100);
      rowValue.push(percentageValue);
      rowValue.push(
        getTooltip(label, "Passed", labelData.Passed, percentageValue)
      );

      percentageValue = getPercentage((labelData.Failed / executed) * 100);
      rowValue.push(percentageValue);
      rowValue.push(
        getTooltip(label, "Failed", labelData.Failed, percentageValue)
      );

      percentageValue = getPercentage(
        (labelData.Blocked / totalTestRuns) * 100
      );
      rowValue.push(percentageValue);
      rowValue.push(
        getTooltip(label, "Blocked", labelData.Blocked, percentageValue)
      );

      percentageValue = getPercentage(
        (labelData.Incomplete / totalTestRuns) * 100
      );
      rowValue.push(percentageValue);
      rowValue.push(
        getTooltip(label, "Incomplete", labelData.Incomplete, percentageValue)
      );

      data.push(rowValue);
    });

    var options = {
      title: "Test Execution Summary",
      titleTextStyle: { fontSize: "16" },
      bars: "vertical",
      vAxis: { minValue: 0, maxValue: 100, format: "#'%'" },
      chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
      colors: ["#3366cc", "#109618", "#dc3912", "#ff9900"],
      legend: {
        position: "",
        maxLines: 1,
      },
    };

    return (
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
      />
    );
  };

  const TestManualVsAutomatedChart = () => {
    let hasData = true;
    let emptyData = [["No data", "No data"]];
    let manualTestsCount =
      DataSource.ADO === currentProjectSourceOfInformation
        ? testExecutionInfo.manualVsAutomatedTests?.data?.Manual?.length
        : testExecutionInfo.testManualVsAutomated?.Manual?.length;
    let automatedTestsCount =
      DataSource.ADO === currentProjectSourceOfInformation
        ? testExecutionInfo.manualVsAutomatedTests?.data?.Automated?.length
        : testExecutionInfo.testManualVsAutomated?.Automation?.length;

    if (!manualTestsCount && !automatedTestsCount) {
      hasData = false;
    }

    const data = [
      ["Manual or Automated", "Amount of items for porcentage"],
      ["Manual", manualTestsCount || 0],
      ["Automation", automatedTestsCount || 0],
    ];

    const options = {
      title: "Manual Tests vs. Automated Tests",
      titleTextStyle: { fontSize: "16" },
      chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
    };

    return (
      <Chart
        chartType="PieChart"
        width="100%"
        height="400px"
        data={hasData ? data : emptyData}
        options={options}
      />
    );
  };

  const TestExecutionChurnChart = () => {
    const data = [
      ["TestRuns", "Regression", "New Feature", "Performance"],
      ["1x", 0, 0, 0],
      ["2x", 0, 0, 0],
      ["3x", 0, 0, 0],
      ["4x", 0, 0, 0],
      ["5x", 0, 0, 0],
      ["6x", 0, 0, 0],
      ["7x", 0, 0, 0],
      ["8x", 0, 0, 0],
      ["9x", 0, 0, 0],
      ["10X", 0, 0, 0],
      ["+10x", 0, 0, 0],
    ];

    if (testExecutionChurnInfo && iterations) {
      const listOfIterations = Object.keys(testExecutionChurnInfo);
      let iterationIndex = "0";
      iterations.forEach((iteration, index) => {
        if (iteration === currentIteration) {
          iterationIndex = listOfIterations[index];
        }
      });
      Object.entries(testExecutionChurnInfo[iterationIndex]).map((element) => {
        let column =
          element[0] === "regression" ? 1 : element[0] === "newFeature" ? 2 : 3;
        const fullFillingInfo = (column) => {
          let counter = -1;
          for (let info in element[1].testRunsStatDto) {
            counter += 1;
            if (counter < 1) {
              continue;
            }
            data[counter][column] = element[1].testRunsStatDto[info];
          }
        };

        fullFillingInfo(column);
      });
    }

    var options = {
      title: "Test Execution Churn",
      titleTextStyle: { fontSize: "16" },
      bars: "vertical",
      vAxis: { format: "#" },
      chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
      colors: ["#3366cc", "#dc3912", "#ff9900"],
      legend: {
        position: "",
        maxLines: 1,
      },
    };

    return (
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
      />
    );
  };

  const AutomatedTestRunByTypeChart = () => {
    const options = {
      title: "Automated Test Run By Testing Type",
      titleTextStyle: { fontSize: "16" },
      chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
    };

    const data = [
      ["Automated Test Run By Testing Type", "Amount of items for porcentage"],
      [
        "Functional",
        testExecutionInfo.testAutomatedTestRunByType?.Functional?.length || 0,
      ],
      [
        "Regression",
        testExecutionInfo.testAutomatedTestRunByType?.Regression?.length || 0,
      ],
    ];

    return (
      <Chart
        chartType="PieChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
      />
    );
  };

  const TestExecutionActualChart = () => {
    let testPlanVsActualNotSorted = testExecutionInfo.testExecutionActual
      ? testExecutionInfo.testExecutionActual[currentIteration]
      : [];

    if (!testPlanVsActualNotSorted) {
      return (
        <div className="form-outline mt-4" id="error-message">
          <div className="alert alert-danger" role="alert">
            Data is empty!
          </div>
        </div>
      );
    }

    let currentTestPlanVsActualInfo = {};

    if (testPlanVsActualNotSorted) {
      Object.keys(testPlanVsActualNotSorted)
        .sort(function (a, b) {
          return new Date(a).getTime() - new Date(b).getTime();
        })
        .forEach(function (key) {
          currentTestPlanVsActualInfo[key] = testPlanVsActualNotSorted[key];
        });
    }
    const dates = Object.keys(currentTestPlanVsActualInfo);
    const totalTestRuns = testExecutionInfo?.testExecutionSummary
      ? testExecutionInfo?.testExecutionSummary[currentIteration]?.Total?.Total
      : [];
    var data = [];
    data[0] = ["Dates", "Planned", "Actual"];
    data[1] = ["Dates", 0, 0];

    const count = dates.length;
    let stepForPlannedExec = Math.round(totalTestRuns / count - 1);
    for (let i = 1; i <= count; i++) {
      data[i] = [
        dates[i - 1],
        Math.round(stepForPlannedExec * (i - 1)),
        currentTestPlanVsActualInfo[dates[i - 1]].Actual,
      ];
    }

    var options = {
      title: "Test Execution Plan vs. Actual",
      titleTextStyle: { fontSize: "16" },
      hAxis: { slantedText: true, slantedTextAngle: 45 },
      chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
      vAxis: { format: "0" },
    };

    try {
      let maxValue = Math.max.apply(null, data[1]);
      if (maxValue < 5) {
        options.vAxis = { format: "#", viewWindow: { max: 4 } };
      }
    } catch (e) {}

    return (
      <Chart
        chartType="LineChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
      />
    );
  };

  const getTooltip = function (date, name, values) {
    var isQtest = true;
    var statuses = {
      Passed: "Passed",
      Failed: "Failed",
      Blocked: "Blocked",
      Incomplete: "Incomplete",
      Unexecuted: "Unexecuted",
    };

    var tooltip =
      '<div align="left" style="width: auto; float: left; table-layout: auto">' +
      '<table class="table table-hover table-condensed table-sm" >' +
      "<thead><tr><th>" +
      date +
      "</th></tr></thead>" +
      "<tbody>";

    for (let statValue in statuses) {
      if (statuses[statValue] === name) {
        if (isQtest) {
          tooltip =
            tooltip +
            '<tr class="table-danger"><td><b>' +
            name
              .replace("In Progress", "In Complete")
              .replace("Not Run", "Unexecuted") +
            "</b>";
        } else {
          tooltip =
            tooltip + '<tr class="table-danger"><td><b>' + name + "</b>";
        }
      } else {
        if (isQtest) {
          tooltip =
            tooltip +
            "<tr><td>" +
            statuses[statValue]
              .replace("In Progress", "In Complete")
              .replace("Not Run", "Unexecuted");
        } else {
          tooltip = tooltip + "<tr><td>" + statuses[statValue];
        }
      }
      tooltip =
        tooltip +
        ": <b>" +
        Math.abs(values[statValue] ? values[statValue] : 0) +
        "</b></td></tr>";
    }
    tooltip = tooltip + "</tbody></table></div>";

    return tooltip;
  };

  //===============================================
  //----------Create ticks array for trending charts
  //===============================================
  const getTicksArrayForTrendingChart = (minValue, maxValue) => {
    // Figure out the largest number (positive or negative)
    var biggestNumber = Math.max(Math.abs(maxValue), Math.abs(minValue));

    // Round to an exponent of 10 appropriate for the biggest number
    var roundingExp = Math.floor(Math.log(biggestNumber) / Math.LN10);
    var roundingDec = Math.pow(10, roundingExp);

    // Round your max and min to the nearest exponent of 10
    var newMax = Math.ceil(maxValue / roundingDec) * roundingDec;
    var newMin = Math.floor(minValue / roundingDec) * roundingDec;

    // Determine the range of your values
    var range = Math.max(Math.abs(newMax), Math.abs(newMin));
    //(newMin<0)?newMax - newMin : newMax;
    var gridlines = 2;

    // Calculate the best factor for number of gridlines (2-5 gridlines)
    // If the range of numbers divided by 2 or 5 is a whole number, use it
    for (var i = 2; i <= 4; ++i) {
      if (Math.round(range / i) === range / i) {
        gridlines = i;
        // break;
      }
    }
    var step = Math.round(range / gridlines);
    var ticksArr = [];
    if (newMin < 0) {
      for (i = 0; i >= newMin - step; i -= step) {
        ticksArr.push({ v: i, f: String(Math.abs(i)) });
        if (minValue >= i) {
          break;
        }
      }
      ticksArr.reverse();
    }
    if (newMax > 0) {
      for (i = 0; i <= newMax + step; i += step) {
        ticksArr.push(i);
        if (i > maxValue) {
          break;
        }
      }
    }
    return ticksArr;
  };

  const TestExecutionTredingChart = () => {
    const getValue = (value) => {
      return value ? value : 0;
    };

    let notSortedInfo = testExecutionInfo.testExecutionTreding
      ? testExecutionInfo.testExecutionTreding[currentIteration]
      : [];

    if (!notSortedInfo) {
      return (
        <div className="form-outline mt-4" id="error-message">
          <div className="alert alert-danger" role="alert">
            Data is empty!
          </div>
        </div>
      );
    }

    let columnArray = [
      "Dates",
      "Passed",
      "Failed",
      "Blocked",
      "Incomplete",
      "Unexecuted",
    ];
    let valEmptyArray = ["Dates", 0, "", 0, "", 0, "", 0, "", 0, ""];

    var maxValue = 0;
    var minValue = 0;
    var currentIterationTrendingInfo = {};
    if (notSortedInfo) {
      Object.keys(notSortedInfo)
        .sort(function (a, b) {
          return new Date(a).getTime() - new Date(b).getTime();
        })
        .forEach(function (key) {
          currentIterationTrendingInfo[key] = notSortedInfo[key];
        });
    }

    var dataTable = [];
    var header = ["Dates"];
    for (var i = 1; i < columnArray.length; i++) {
      header.push(columnArray[i]);
      header.push({ type: "string", role: "tooltip", p: { html: true } });
    }
    dataTable.push(header);

    const datesArr = Object.keys(currentIterationTrendingInfo);
    var isStatEmpty = true;
    for (let date of datesArr) {
      isStatEmpty = false;
      var tempMaxValue = 0;
      var tempMinValue = 0;
      var tempStatObj = currentIterationTrendingInfo[date];
      tempStatObj.Passed = tempStatObj.Passed ? tempStatObj.Passed : 0;
      tempStatObj.Failed = tempStatObj.Failed ? tempStatObj.Failed : 0;
      tempStatObj.Blocked = tempStatObj.Blocked ? tempStatObj.Blocked : 0;
      tempStatObj.Incomplete = tempStatObj.Incomplete
        ? tempStatObj.Incomplete
        : 0;
      tempStatObj.InProgress = tempStatObj.InProgress
        ? tempStatObj.InProgress
        : 0;
      tempStatObj.Unexecuted = tempStatObj.Unexecuted
        ? tempStatObj.Unexecuted
        : 0;

      var dataArray = [
        date,
        getValue(tempStatObj.Passed),
        getTooltip(date, "Passed", tempStatObj),
        getValue(tempStatObj.Failed),
        getTooltip(date, "Failed", tempStatObj),
        getValue(tempStatObj.Blocked),
        getTooltip(date, "Blocked", tempStatObj),
        getValue(tempStatObj.Incomplete),
        getTooltip(date, "Incomplete", tempStatObj),
        getValue(tempStatObj.Unexecuted * -1),
        getTooltip(date, "Unexecuted", tempStatObj),
      ];
      dataTable.push(dataArray);

      tempMaxValue = getValue(tempStatObj.Total);
      tempMinValue = getValue(tempStatObj.Unexecuted * -1);
      if (tempMaxValue > maxValue) {
        maxValue = tempMaxValue;
      }
      if (minValue > tempMinValue) {
        minValue = tempMinValue;
      }
    }

    // set default value if no results received from server
    if (isStatEmpty) {
      dataTable.push(valEmptyArray);
    }

    var data = dataTable;

    var ticksArr = getTicksArrayForTrendingChart(minValue, maxValue);

    var options = {
      title: "Test Execution Trending",
      titleTextStyle: { fontSize: "16" },
      bars: "vertical", // Required for Material Bar Charts.

      isStacked: true,
      hAxis: { slantedText: true, slantedTextAngle: 45 },
      chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
      tooltip: { isHtml: true, trigger: "visible" },
      vAxis: {
        ticks: ticksArr,
        format: "0",
      },
    };

    try {
      let maxValue = Math.max.apply(null, data[1]);
      if (maxValue < 5) {
        options.vAxis = { format: "#", viewWindow: { max: 4 } };
      }
    } catch (e) {}

    return (
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
      />
    );
  };

  const TestManualRunROI = () => {
    const getTooltip = function (timeInHoursCount, totalTestsCount) {
      return (
        "Time in Hours: " +
        timeInHoursCount +
        "\nTotal Tests: " +
        totalTestsCount
      );
    };
    const { projectDetails } = useProjectDetails();
    let searchIteration = projectDetails.lpIterations.filter((iteration) => {
      return iteration.iterationName === currentIteration;
    });
    const testManualRunROIData = testExecutionInfo.testManualTestRunROI;
    const defaultIteration = currentIteration === "Overall";
    const currentTestManualRunROIData = defaultIteration
      ? testManualRunROIData[0]
      : testManualRunROIData[searchIteration[0].id];
    let dataTable = [];
    let dataTableHeader = [
      "Type of Test",
      "Total Tests",
      "Time in Hours",
      { type: "string", role: "tooltip", p: { html: true } },
    ];

    if (
      !currentTestManualRunROIData ||
      Object.keys(currentTestManualRunROIData.manualTestRunCountByType)
        .length <= 0 ||
      Object.keys(currentTestManualRunROIData.manualTestRunTimesByType)
        .length <= 0
    ) {
      return (
        <div className="form-outline mt-4" id="error-message">
          <div className="alert alert-danger" role="alert">
            Data is empty!
          </div>
        </div>
      );
    }

    const labels = Object.keys(
      currentTestManualRunROIData["manualTestRunCountByType"]
    );

    dataTable.push(dataTableHeader);
    labels.forEach((label) => {
      let currentTotalTests =
        currentTestManualRunROIData.manualTestRunCountByType[label];
      let currentTotalMinutesPerTests =
        currentTestManualRunROIData.manualTestRunTimesByType[label];
      let currentTotalHoursPerTests = Math.floor(
        currentTotalMinutesPerTests / 3600
      );

      dataTable.push([
        `${label}`,
        currentTotalTests,
        currentTotalHoursPerTests,
        getTooltip(currentTotalHoursPerTests, currentTotalTests),
      ]);
    });

    let counterTotalTest = 0;
    let counterTotalHours = 0;
    dataTable.forEach((row, index) => {
      if (row[index] === "Type of Test") {
        return;
      }

      counterTotalTest += row[1];
      counterTotalHours += row[2];
    });

    let totalArrayToPush = [
      "Total",
      counterTotalTest,
      counterTotalHours,
      getTooltip(counterTotalHours, counterTotalTest),
    ];
    dataTable.splice(1, 0, totalArrayToPush);

    var options = {
      title: "Manual Test Run ROI",
      titleTextStyle: { fontSize: "16" },
      bars: "vertical",
      vAxis: { format: "#", logScale: true },
      chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
      colors: ["#3366cc", "#109618", "#dc3912", "#ff9900"],
      legend: {
        position: "",
        maxLines: 1,
      },
    };

    return (
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={dataTable}
        options={options}
      />
    );
  };

  const TestExecutionFailureByTypeChart = () => {
    let activeIteration =
      currentIteration === "Overall"
        ? testExecutionInfo.testExecutionFailureByTypeTrending[0]
        : testExecutionInfo.testExecutionFailureByTypeTrending[
            currentIteration
          ];
    let notSortedInfo = testExecutionInfo.testExecutionFailureByType;
    let finalDataArray = [];
    let headerArray = ["Date", "Failed tests"];

    if (
      !notSortedInfo ||
      !notSortedInfo?.message ||
      notSortedInfo?.message.startsWith("Test info cannot") ||
      notSortedInfo?.testRuns?.length <= 0 ||
      Object.keys(notSortedInfo.stats)?.length <= 0
    ) {
      finalDataArray.push(headerArray);
      finalDataArray.push(["No data", 0]);
    }

    Object.entries(notSortedInfo.stats[0]).forEach((date) => {
      let counter = 0;
      Object.entries(date[1]).forEach((entry) => {
        counter += entry[1].length;
      });

      finalDataArray.push([date[0], counter]);
    });

    if (finalDataArray.length > 2) {
      finalDataArray.sort((a, b) => Date.parse(a[0]) - Date.parse(b[0]));
    }

    let isSlantedText =
      finalDataArray.length > 2
        ? { slantedText: true, slantedTextAngle: 45 }
        : { slantedText: false };

    var options = {
      title: "Test Execution Failure by Type",
      titleTextStyle: { fontSize: "16" },
      bars: "vertical",
      isStacked: true,
      hAxis: isSlantedText,
      chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
      tooltip: { isHtml: true, trigger: "visible" },
    };

    const chartEvents = [
      {
        eventName: "ready",
        callback: ({ chartWrapper, google }) => {
          var handler = async function () {
            const chart = chartWrapper.getChart();
            var selection = chart.getSelection();
            if (!selection || selection.length < 1) {
              return;
            }
            var defectTitles = ["ID", "Name", "Status", "Defect", "Type"];
            var row = selection[0].row + 1;
            let selectedDate = finalDataArray[row][0];
            let testRunsData = Object.entries(notSortedInfo.stats[0]);
            let testCasesDataModal = [];
            let selectedData = testRunsData.find((data) => {
              return data[0] === selectedDate;
            });

            if (selectedData === undefined) return;
            let selectedFailedTests = Object.entries(selectedData[1]);

            selectedFailedTests.forEach((test) => {
              test[1].forEach((testId) => {
                let findTestId = notSortedInfo.testRuns.find((item) => {
                  return item.pid === testId;
                });

                testCasesDataModal.push({
                  id: findTestId.pid,
                  name: findTestId.name,
                  defect: findTestId.pid,
                  status: findTestId.status,
                  link: findTestId.webUrl,
                  type: test[0],
                });
              });
            });

            setModalData({
              title: `Failed Test Cases: ${selectedDate}`,
              testCasesData: testCasesDataModal,
              headers: defectTitles,
              idModal: "testExecutionFailureByTypeChart",
            });
            setShowModal(true);
          };

          google.visualization.events.addListener(
            chartWrapper.getChart(),
            "select",
            () => handler()
          );
        },
      },
    ];

    return (
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={finalDataArray}
        options={options}
        chartEvents={chartEvents}
      />
    );
  };

  const TestExecutionFailureByTypeTrendingChart = () => {
    let activeIteration =
      currentIteration === "Overall"
        ? testExecutionInfo.testExecutionFailureByTypeTrending[0]
        : testExecutionInfo.testExecutionFailureByTypeTrending[
            currentIteration
          ];
    let notSortedInfo = testExecutionInfo.testExecutionFailureByTypeTrending;
    let finalDataArray = [];
    let headerArray = ["Date", "Failed tests"];

    if (!Object.keys(notSortedInfo.testruns).length > 0) {
      finalDataArray.push(headerArray);
      finalDataArray.push(["No data", 0]);
    }

    let dataTestsRuns = Object.values(notSortedInfo.testruns);
    let dataTestsRunWithKeys = Object.entries(notSortedInfo.testruns);

    let datesForTestRuns = dataTestsRuns.map((test) => {
      let transformDateFormat = test.createdDate.split(" ")[0];
      return transformDateFormat;
    });
    let filteredDatesForTestRuns = removeDuplicatesDates(datesForTestRuns);

    filteredDatesForTestRuns.forEach((date) => {
      let counter = 0;

      dataTestsRunWithKeys.forEach((test) => {
        let transformDateFormat = test[1].createdDate.split(" ")[0];

        if (transformDateFormat === date) {
          counter += 1;
        }
      });

      finalDataArray.push([date, counter]);
    });

    let isSlantedText =
      finalDataArray.length > 2
        ? { slantedText: true, slantedTextAngle: 45 }
        : { slantedText: false };

    var options = {
      title: "Test Execution Failure by Type Trending",
      titleTextStyle: { fontSize: "16" },
      bars: "vertical",
      isStacked: true,
      hAxis: isSlantedText,
      chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
      tooltip: { isHtml: true, trigger: "visible" },
    };

    const chartEvents = [
      {
        eventName: "ready",
        callback: ({ chartWrapper, google }) => {
          var handler = async function () {
            const chart = chartWrapper.getChart();
            var selection = chart.getSelection();
            var defectTitles = ["ID", "Name", "Status", "Defect"];
            var row = selection[0].row + 1;
            let selectedDate = finalDataArray[row][0];
            let testRunsData = Object.values(notSortedInfo.testruns);
            let testCasesDataModal = [];

            testRunsData.forEach((test) => {
              let transformDateFormat = test.createdDate.split(" ")[0];

              if (transformDateFormat === selectedDate) {
                testCasesDataModal.push({
                  id: test.pid,
                  name: test.name,
                  status: test.status,
                  defect: test.defectKey,
                  link: test.webUrl,
                });
              }
            });

            setModalData({
              title: `Failed Test Cases: ${selectedDate}`,
              testCasesData: testCasesDataModal,
              headers: defectTitles,
              idModal: "testExecutionFailureByTypeTrendingChart",
            });
            setShowModal(true);
          };

          google.visualization.events.addListener(
            chartWrapper.getChart(),
            "select",
            () => handler()
          );
        },
      },
    ];

    return (
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={finalDataArray}
        options={options}
        chartEvents={chartEvents}
      />
    );
  };

  const handleIterationClick = (event) => {
    setCurrentIteration(event.target.id);
  };

  const removeDuplicatesDates = (array) => {
    return array.filter((item, index) => array.indexOf(item) === index);
  };

  return (
    <div id="main_container">
      <TestCasesDevelopmentModal
        isOpen={showModal}
        setShowModal={setShowModal}
        setModalData={setModalData}
        data={modalData}
        token={tokenId}
      />
      {loading ? (
        <Loading />
      ) : (
        <div
          id="main_container_1"
          className="bg-light  container-fluid pt-3 pb-3"
        >
          <h4 className="ms-4">Test Execution</h4>
          <ErrorMessage errorMessage={errorMessage} />
          <div id="sprints-container" className="container-fluid pt-3 pb-3">
            <div
              className="btn-group ms-4"
              role="group"
              aria-label="Iterations"
            >
              {iterations &&
                iterations.map((iteration, index) => {
                  return (
                    <div key={index} className="ms-1">
                      <button
                        type="button"
                        id={iteration}
                        className={
                          "btn btn-outline-primary " +
                          (iteration === currentIteration ? "active" : "")
                        }
                        onClick={handleIterationClick}
                      >
                        {iteration}
                      </button>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="row ms-3 row-cols-auto justify-content-around">
            <div className="col col-5 mb-4 me-4">
              <div id="test-execution-summary-root">
                {testExecutionInfo &&
                  DataSource.ADO !== currentProjectSourceOfInformation && (
                    <TestExecutionChart />
                  )}
              </div>
            </div>
            <div className="col col-5 mb-4 me-4">
              <div id="test-execution-summary-root">
                {testExecutionInfo &&
                  DataSource.ADO !== currentProjectSourceOfInformation && (
                    <TestExecutionChurnChart />
                  )}
              </div>
            </div>
            <div className="col col-5 mb-4 me-4">
              <div id="test-execution-trending-root">
                {testExecutionInfo &&
                  DataSource.ADO !== currentProjectSourceOfInformation && (
                    <TestExecutionTredingChart />
                  )}
              </div>
            </div>
            <div className="col col-5 mb-4 me-4">
              <div id="test-execution-actual-root">
                {testExecutionInfo &&
                  DataSource.ADO !== currentProjectSourceOfInformation && (
                    <TestExecutionActualChart />
                  )}
              </div>
            </div>
            <div className="col col-5 mb-4 me-4">
              <div id="test-manual-vs-automated-root">
                {testExecutionInfo && <TestManualVsAutomatedChart />}
              </div>
            </div>
            <div className="col col-5 mb-4 me-4">
              <div id="test-automated-run-by-type-root">
                {testExecutionInfo &&
                  DataSource.ADO !== currentProjectSourceOfInformation && (
                    <AutomatedTestRunByTypeChart />
                  )}
              </div>
            </div>
            {/* <div className="col col-5 mb-4 me-4">
              <div id="test-execution-failure-by-type-root">
                {testExecutionInfo && <TestExecutionFailureByTypeChart />}
              </div>
            </div> */}
            {/* <div className="col col-5 mb-4 me-4">
              <div id="test-execution-failure-by-type-trending-root">
                {testExecutionInfo && (
                  <TestExecutionFailureByTypeTrendingChart />
                )}
              </div>
            </div> */}
            <div className="col col-5 mb-4 me-4">
              <div id="test-manual-run-roi-root">
                {testExecutionInfo &&
                  DataSource.ADO !== currentProjectSourceOfInformation && (
                    <TestManualRunROI />
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
