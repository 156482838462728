import useJiraInfo from "../../hooks/useJiraInfo";
import { useEffect, useRef } from "react";
import { Chart } from "react-google-charts";

const getTooltip = function (statuses, date, name, values) {
  var tooltip =
    '<div align="left" style="width: auto; float: left; table-layout: auto">' +
    '<table class="table table-hover table-condensed table-sm" >' +
    "<thead><tr><th>" +
    date +
    "</th></tr></thead>" +
    "<tbody>";

  let index = 0;
  for (let statValue of statuses) {
    if (statValue === name) {
      tooltip =
        tooltip +
        '<tr class="table-danger" key={' +
        index +
        "}><td><b>" +
        name +
        "</b>";
    } else {
      tooltip = tooltip + "<tr  key={" + index + "}><td>" + statValue;
    }
    tooltip =
      tooltip +
      ": <b>" +
      Math.abs(values[statValue]?.length ? values[statValue].length : 0) +
      "</b></td></tr>";
    index++;
  }
  tooltip = tooltip + "</tbody></table></div>";
  return tooltip;
};

export default function DefectsAgeChart(props) {
  const { currentStateDefectAge, setCurrentStateDefectAge } = useJiraInfo();
  var stateRadioList = ["All", "Open", "Closed", "Done"];
  const severities =
    props.projectTestSource === "ADO"
      ? ["1 - Severe", "2 - High", "3 - Medium", "4 - Low", "Not Defined"]
      : ["Sev 1-Severe", "Sev 2-High", "Sev 3-Medium", "Sev 4-Low"];
  const selectedAgeStateValueRef = useRef();
  const currentIterationInfoRef = useRef();

  useEffect(() => {
    selectedAgeStateValueRef.current = currentStateDefectAge;
  }, [currentStateDefectAge]);

  var defectTitles = [
    "Issue ID",
    "Issue Summary",
    "Status",
    "Resolution",
    "Severity",
    "Assignee",
  ];
  let severitiesColors = {};
  severities.forEach((severity, index) => {
    let colors = {
      0: "#dc3a12",
      1: "#ffbf00",
      2: "#3366cc",
      3: "green",
    };
    severitiesColors[severity] = colors[index];
  });

  var ageValues = {};
  for (let i = 1; i <= 11; i++) {
    let ageValue = i + " wk";
    if (i === 11) ageValue = "+10 wk";

    ageValues[i] = ageValue;
  }

  const data_from_props =
    props.isADO === "ADO"
      ? props.jiraChartsInfo?.defectAgeChartMap?.data?.defectAgeChartMap
      : props.jiraChartsInfo?.defectAgeChartMap;
  let currentIterationInfo = {
    All: null,
    Open: null,
    Closed: null,
    Done: null,
  };

  if (props.jiraChartsInfo?.defectAgeChartMap && data_from_props) {
    currentIterationInfo = data_from_props[props.currentIteration];
  } else {
    currentIterationInfo = [] || null;
  }

  let issueMap = {};
  let issuesList = {};

  issueMap =
    props.isADO === "ADO"
      ? props.jiraChartsInfo?.defectAgeChartMap?.data?.defectAgeChartMap
      : props.jiraChartsInfo?.issueMap;

  issuesList =
    props.isADO === "ADO"
      ? props.jiraChartsInfo?.defectAgeChartMap?.data?.issueMap
      : props.jiraChartsInfo?.issueMap;
  currentIterationInfoRef.current = currentIterationInfo;
  var dataTable = {};

  let headerArr = [];
  headerArr.push("Age");

  for (let sev of severities) {
    headerArr.push(sev);
    headerArr.push({
      type: "string",
      role: "tooltip",
      p: { html: true },
    });
    headerArr.push({ role: "style" });
  }
  for (var state of stateRadioList) {
    dataTable[state] = [];
    dataTable[state].push(headerArr.slice());
  }

  //prepare emty values
  var emptyStateData = [];
  emptyStateData.push(headerArr);
  for (let i = 1; i <= 11; i++) {
    let emptyDataArray = [];
    let ageValue = ageValues[i];
    emptyDataArray.push(ageValue);

    severities.forEach(() => {
      emptyDataArray.push(0);
      emptyDataArray.push("");
      emptyDataArray.push("none");
    });
    emptyStateData.push(emptyDataArray);
  }

  for (let state in currentIterationInfoRef.current) {
    let data = currentIterationInfoRef.current[state];

    for (let i = 1; i <= 11; i++) {
      let ageValue = ageValues[i];
      let tempMap = data?.[i + ""] ? data?.[i + ""] : {};
      var dataArray = [];
      dataArray.push(ageValue);

      for (let sev of severities) {
        dataArray.push(tempMap[sev]?.length ? tempMap[sev].length : 0);
        var tooltip = getTooltip(severities, ageValue, sev, tempMap);
        dataArray.push(tooltip);
        dataArray.push(severitiesColors[sev]);
      }
      dataTable[state].push(dataArray);
    }
  }

  //check if some state data is misssed and add empty data array
  for (let i in stateRadioList) {
    let state = stateRadioList[i];
    if (
      currentIterationInfoRef.current &&
      !currentIterationInfoRef.current[state]
    ) {
      dataTable[state] = emptyStateData;
    }
  }

  var options = {
    title: "Defect Age",
    titleTextStyle: { fontSize: "16" },
    bars: "vertical",
    isStacked: true,
    hAxis: { slantedText: true, textStyle: { fontSize: 11 } },
    vAxis: { format: "0" },
    chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
    tooltip: { isHtml: true },
    colors: ["#dc3a12", "#ffbf00", "#3366cc", "green", "violet"],
  };

  var chartEvents = [
    {
      eventName: "ready",
      callback: ({ chartWrapper, google }) => {
        var handler = async function () {
          const chart = chartWrapper.getChart();
          var selection = chart.getSelection();

          if (!selection || selection.length < 1) {
            return;
          }
          var row = selection[0].row;
          var col = selection[0].column;

          var selectedSeverity =
            dataTable[selectedAgeStateValueRef.current][0][col];
          var selectedAge = "";
          if (
            dataTable[selectedAgeStateValueRef.current][row + 1] &&
            dataTable[selectedAgeStateValueRef.current][row + 1].length > 0
          ) {
            selectedAge =
              dataTable[selectedAgeStateValueRef.current][row + 1][0];
          }

          var selectedAgeIndex = Object.keys(ageValues).filter((ageIndex) => {
            return ageValues[ageIndex] === selectedAge;
          });

          if (selectedAgeIndex.length > 0) {
            selectedAgeIndex = selectedAgeIndex[0];
          }
          selectedAgeIndex = selectedAgeIndex ? selectedAgeIndex + "" : "-1";

          var defectsIdsArray = currentIterationInfoRef.current?.[
            selectedAgeStateValueRef.current
          ][selectedAgeIndex]?.[selectedSeverity]
            ? currentIterationInfoRef.current[selectedAgeStateValueRef.current][
                selectedAgeIndex
              ][selectedSeverity]
            : [];
          var defectsInfoArray = [];

          Object.keys(issuesList).forEach((issueId) => {
            if (defectsIdsArray.indexOf(issueId) >= 0) {
              defectsInfoArray.push(issuesList[issueId]);
            }
          });

          props.setModalData({
            title: selectedAge + ": " + selectedSeverity,
            projectId: props.currentProjectId,
            createdDt: null,
            releaseName: selectedAge,
            status: selectedAgeStateValueRef.current,
            severity: selectedSeverity,
            tableTitles: defectTitles,
            defects: defectsInfoArray,
          });
          if (defectsInfoArray.length < 1) {
            props.setShowModal(false);
          } else {
            props.setShowModal(true);
          }
        };

        google.visualization.events.addListener(
          chartWrapper.getChart(),
          "select",
          () => handler()
        );
      },
    },
  ];

  var handleAgeStateChange = async (event) => {
    setCurrentStateDefectAge(event.target.value);
  };
  let data = {};

  if (currentIterationInfoRef.current) {
    data = dataTable[currentStateDefectAge];
  } else {
    data = emptyStateData;
  }

  let list_of_state = [];

  if (
    props?.jiraChartsInfo?.defectAgeChartMap?.data?.defectAgeChartMap &&
    props?.jiraChartsInfo?.defectAgeChartMap?.data?.defectAgeChartMap["Overall"]
  ) {
    list_of_state = Object.keys(
      props?.jiraChartsInfo?.defectAgeChartMap?.data?.defectAgeChartMap[
        "Overall"
      ]
    );
  }

  return (
    <div>
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
        chartEvents={chartEvents}
      />
      <div
        id="defect-age-state-radio"
        role="group"
        className="btn-group ms-4"
        style={{ display: "flex", alignItems: "baseline" }}
      >
        <input
          type="radio"
          id="age-all"
          name="optionsAge"
          className="mb-4"
          value="All"
          autoComplete="off"
          checked={currentStateDefectAge === "All"}
          onChange={handleAgeStateChange}
        />
        <label
          htmlFor="age-All"
          className="mb-4"
          style={{ paddingLeft: "5px" }}
        >
          All
        </label>
        <input
          type="radio"
          id="age-open"
          value="Open"
          name="optionsAge"
          autoComplete="off"
          className="ms-4 "
          checked={currentStateDefectAge === "Open"}
          onChange={handleAgeStateChange}
        />
        <label
          htmlFor="age-open"
          className="mb-4"
          style={{ paddingLeft: "5px" }}
        >
          Open
        </label>
        <input
          type="radio"
          id="age-closed"
          value={list_of_state.includes("Done") ? "Done" : "Closed"}
          name="optionsAge"
          autoComplete="off"
          className="ms-4 "
          checked={
            props.isADO === "ADO"
              ? currentStateDefectAge === "Closed" ||
                currentStateDefectAge === "Done"
              : currentStateDefectAge === "Closed"
          }
          onChange={handleAgeStateChange}
        />
        <label
          htmlFor="age-closed"
          className="mb-4"
          style={{ paddingLeft: "5px" }}
        >
          {props.isADO === "ADO" ? "Closed/Done" : "Closed"}
        </label>
      </div>
    </div>
  );
}
