import React, { useState, useEffect } from "react";
import Jira from "../../services/Jira";
import ADO from "../../services/ADO";
import DefectsModal from "../../components/common/modals/DefectsModal";
import Loading from "../../components/common/Loading";
import ErrorMessage from "../../components/common/ErrorMessage";
import OpenClosedDefectsChart from "../../components/charts/defectsCharts/OpenClosedDefectsChart";
import DefectTrendingChart from "../../components/charts/defectsCharts/DefectTrendingChart";
import DefectsVsReleaseItemsChart from "../../components/charts/defectsCharts/DefectsVsReleaseItemsChart";
import TargetedOpenDefectsBySprintChart from "../../components/charts/defectsCharts/TargetedOpenDefectsBySprintChart";
import DefectsAgeChart from "../../components/charts/defectsCharts/DefectsAgeChart";
import DefectsSeverityChart from "../../components/charts/defectsCharts/DefectsSeverityChart";
import DefectsChurnChart from "../../components/charts/defectsCharts/DefectsChurnChart";
import ItemsWithResolvedStatusChart from "../../components/charts/defectsCharts/ItemsWithResolvedStatusChart";
import ResolvedAndAssignedItemsChart from "../../components/charts/defectsCharts/ResolvedAndAssignedItemsChart";
import QADefectsFoundCurrentRelease from "../../components/charts/defectsCharts/QADefectsFoundInCurrentRelease";
import OpenIssueBacklogChart from "../../components/charts/defectsCharts/OpenIssueBacklogChart";
import DefectsPerStoryPerSprintChart from "../../components/charts/defectsCharts/DefectsPerStoryPerSprintChart";
import useErrorMessage from "../../components/hooks/useErrorMessage";
import useJiraInfo from "../../components/hooks/useJiraInfo";
import useProjectDetails from "../../components/hooks/useProjectDetails";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../contexts/AppContext";
import "../../components/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

async function getJiraChartsInfo(token, projectId) {
  try {
    const resp = await Jira.getJiraChartsInfo(token, projectId);
    return resp;
  } catch (err) {
    return {
      message:
        "Error Jira info (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

async function getDefectsPerStoryPerSpring(token, projectId) {
  try {
    const resp = await Jira.getDefectsPerStoryPerSpring(token, projectId);
    return resp;
  } catch (err) {
    return {
      message:
        "Error Jira info (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

async function getADOChartsInfo(token, projectId, body) {
  try {
    const resp = await ADO.getADOChartsInfo(token, projectId, body);
    return resp;
  } catch (err) {
    return {
      message:
        "Error Jira info (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

async function getIssueBacklogIfo(token, projectId) {
  try {
    const resp = await Jira.getIssueBacklogIfo(token, projectId);
    return resp;
  } catch (err) {
    return {
      message:
        "Error Jira info (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

export default function DefectsPage(props) {
  const { jiraChartsInfo, setJiraChartsInfo } = useJiraInfo();
  const [openBacklogInfo, setOpenBackLogInfo] = useState([]);
  const [defectsPerStoryInfo, setDefectsPerStoryInfo] = useState([]);
  const [issues, setIssues] = useState([]);
  const { setErrorMessage, errorMessage } = useErrorMessage();
  const [loading, setLoading] = useState(true);
  const [iterations, setIterations] = useState([]);
  const [currentIteration, setCurrentIteration] = useState("Overall");
  const [showModal, setShowModal] = useState(false);
  const [ADOChatsInfo, setADOChatsInfo] = useState("");
  const { projectId } = useParams();
  const { currentProjectDetails } = useAppContext();
  const DataSource = {
    JIRA: "JIRA",
    ADO: "ADO",
    QTEST: "qTest",
  };
  const [modalData, setModalData] = useState({
    title: "",
    projectId: null,
    createdDt: null,
    releaseName: null,
    status: null,
    severity: null,
  });
  const { projectDetails } = useProjectDetails();
  const currentProjectSourceOfInformation =
    currentProjectDetails?.projectTestSource;
  /*
  TODO delete this
  // const currentProjectSourceOfInformation = JSON.parse(
  //   localStorage.getItem("currentProjectDetails")
  // )?.projectTestSource;
*/
  useEffect(() => {
    function handleScrollYChange() {
      const lastScrollY = window.scrollY;
      if (lastScrollY) {
        sessionStorage.setItem("scrollY", lastScrollY);
      }
    }

    window.addEventListener("scroll", handleScrollYChange, true);
    return () => {
      window.removeEventListener("scroll", handleScrollYChange);
    };
  }, []);

  // let dataForTable = {};

  useEffect(() => {
    const handleJiraDetails = async (e) => {
      let currentProjectId = projectId;
      // var url = window.location.href;
      // let res = /project\/(\d+)/g.exec(url);

      // if (!currentProjectId) {
      //   if (res?.length > 1) {
      //     currentProjectId = res[1];
      //     props.setCurrentProjectId(currentProjectId);
      //   } else {
      //     setErrorMessage("Project is not selected!");
      //     setLoading(false);
      //     return;
      //   }
      // }

      if (
        (!jiraChartsInfo || jiraChartsInfo.projectId !== currentProjectId) &&
        !errorMessage
      ) {
        const response =
          DataSource.ADO === currentProjectSourceOfInformation
            ? await getADOChartsInfo(
                props.token,
                currentProjectId,
                projectDetails
              )
            : await getJiraChartsInfo(props.token, currentProjectId);
        const issueBacklogResponse =
          DataSource.ADO !== currentProjectSourceOfInformation
            ? await getIssueBacklogIfo(props.token, currentProjectId)
            : {};

        if (DataSource.ADO !== currentProjectSourceOfInformation) {
          const getDefectsPerStoryResponse =
            await Jira.getDefectsPerStoryPerSpring(
              props.token,
              currentProjectId
            );

          if (getDefectsPerStoryResponse?.errMsg) {
            setErrorMessage(response?.errMsg);
            setDefectsPerStoryInfo(null);
          } else {
            if (getDefectsPerStoryResponse) {
              setDefectsPerStoryInfo(getDefectsPerStoryResponse.data);
            } else {
              setDefectsPerStoryInfo([]);
            }
          }
        }

        if (
          issueBacklogResponse?.errMsg ||
          DataSource.ADO === currentProjectSourceOfInformation
        ) {
          setErrorMessage(response?.errMsg);
          setOpenBackLogInfo(null);
        } else {
          if (issueBacklogResponse && issueBacklogResponse?.openIssueStatMap) {
            setOpenBackLogInfo(
              Object.entries(issueBacklogResponse?.openIssueStatMap)
            );
          } else {
            setOpenBackLogInfo(Object.entries([]));
          }
          setIssues(issueBacklogResponse?.issues);
        }

        let hasResponseErrors;
        if (DataSource.ADO === currentProjectSourceOfInformation) {
          hasResponseErrors = Object.values(response).some(
            (res) => res.statusCode === 500
          );
        }

        if (response && false) {
          setErrorMessage(
            "Oops!! There was an error retrieving information, please try again later."
          );
          setJiraChartsInfo(null);
        } else {
          if (
            !response?.openClosedDefects &&
            DataSource.ADO !== currentProjectSourceOfInformation
          ) {
            setErrorMessage(
              "Oops!! There was an error retrieving information, please try again later."
            );
            setJiraChartsInfo(null);
          } else {
            setErrorMessage("");
            setJiraChartsInfo((previousObject) => ({
              ...previousObject,
              ...response,
            }));

            if (currentProjectSourceOfInformation == "ADO") {
              setIterations(
                response?.defectAgeChartMap?.data?.productSprintMap
              );
            } else {
              setIterations(response?.productSprintMap);
            }
          }
        }
      } else {
        if (jiraChartsInfo) {
          if (jiraChartsInfo?.data?.defectAgeChartMap?.data?.productSprintMap) {
            setIterations(
              jiraChartsInfo?.data?.defectAgeChartMap?.data?.productSprintMap
            );
          } else {
            setErrorMessage(
              "Oops!! There was an error retrieving information, please try again later."
            );
            setJiraChartsInfo(null);
          }
        }
      }

      setLoading(false);
      sessionStorage.setItem("scrollY", "");
    };
    props.changeStyle("defects");
    handleJiraDetails();
    sessionStorage.removeItem("qadefectfound");
  }, [props.currentProjectId]);

  const handleIterationClick = (event) => {
    setCurrentIteration(event.target.id);
  };

  return (
    <div id="main_container">
      <DefectsModal
        isOpen={showModal}
        setShowModal={setShowModal}
        data={modalData}
        token={props.token}
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          <div
            id="main_container_1"
            className="bg-light  container-fluid pt-3 pb-3"
          >
            <h4 className="ms-4">Defect Metrics</h4>
            <ErrorMessage errorMessage={errorMessage} />
            <div id="sprints-container" className="container-fluid pt-3 pb-3">
              <div
                className="btn-group ms-4"
                role="group"
                aria-label="Iterations"
              >
                <div key={0} className={"ms-1"}>
                  <button
                    type="button"
                    key={0}
                    id={"Overall"}
                    className={
                      "btn btn-outline-primary " +
                      ("Overall" === currentIteration ? "active" : "")
                    }
                    onClick={handleIterationClick}
                  >
                    Overall
                  </button>
                </div>
                {iterations &&
                currentProjectSourceOfInformation !== DataSource.ADO
                  ? Object.keys(iterations).map((iterationId, index) => {
                      return (
                        <div key={index} className={"ms-1"}>
                          <button
                            type="button"
                            key={index}
                            id={iterationId}
                            className={
                              "btn btn-outline-primary " +
                              (iterationId === currentIteration ? "active" : "")
                            }
                            onClick={handleIterationClick}
                          >
                            {iterations[iterationId]}
                          </button>
                        </div>
                      );
                    })
                  : iterations &&
                    iterations.map((iteration, index) => {
                      return (
                        <div key={index} className={"ms-1"}>
                          <button
                            type="button"
                            key={iteration.id}
                            id={iteration.iterationId}
                            className={
                              "btn btn-outline-primary " +
                              (iteration.iterationId === currentIteration
                                ? "active"
                                : "")
                            }
                            onClick={handleIterationClick}
                          >
                            {iteration.iterationName}
                          </button>
                        </div>
                      );
                    })}
              </div>
            </div>
            <div className="row ms-3 row-cols-auto justify-content-around">
              <div id="defectsAgeChart" className="col col-5 mb-4">
                <div id="defects-age-chart-root">
                  <DefectsAgeChart
                    projectTestSource={projectDetails.projectTestSource}
                    jiraChartsInfo={jiraChartsInfo}
                    currentIteration={currentIteration}
                    setModalData={setModalData}
                    setShowModal={setShowModal}
                    currentProjectId={props.currentProjectId}
                    isADO={currentProjectSourceOfInformation}
                  />
                </div>
              </div>
              <div id="defectsChurnChart" className="col col-5 mb-4">
                <div id="defects-churn-chart-root">
                  <DefectsChurnChart
                    jiraChartsInfo={jiraChartsInfo}
                    currentIteration={currentIteration}
                    setModalData={setModalData}
                    setShowModal={setShowModal}
                    currentProjectId={props.currentProjectId}
                    isADO={currentProjectSourceOfInformation}
                  />
                </div>
              </div>
              <div id="defectTrendingChart" className="col col-5 mb-4">
                <div id="defect-trending-root">
                  <DefectTrendingChart
                    jiraChartsInfo={jiraChartsInfo}
                    currentIteration={currentIteration}
                    setModalData={setModalData}
                    setShowModal={setShowModal}
                    currentProjectId={props.currentProjectId}
                    isADO={currentProjectSourceOfInformation}
                  />
                </div>
              </div>
              <div id="defectsSeverityChart" className="col col-5 mb-4">
                <div id="defects-severity-chart-root">
                  <DefectsSeverityChart
                    jiraChartsInfo={jiraChartsInfo}
                    currentIteration={currentIteration}
                    currentProjectId={props.currentProjectId}
                    setModalData={setModalData}
                    setShowModal={setShowModal}
                    isADO={currentProjectSourceOfInformation}
                  />
                </div>
              </div>
              <div id="openClosedDefectsChart" className="col col-5 mb-4">
                <div id="open-closed-defects-root">
                  <OpenClosedDefectsChart
                    jiraChartsInfo={jiraChartsInfo}
                    currentIteration={currentIteration}
                    setModalData={setModalData}
                    setShowModal={setShowModal}
                    currentProjectId={props.currentProjectId}
                    isADO={currentProjectSourceOfInformation}
                  />
                </div>
              </div>
              {jiraChartsInfo &&
                DataSource.ADO !== currentProjectSourceOfInformation && (
                  <div
                    id="qaDefectsFoundInCurrentReleaseChart"
                    className="col col-5 mb-4"
                  >
                    <div id="qa-defects-found-current-release-chart-root">
                      <QADefectsFoundCurrentRelease
                        jiraChartsInfo={jiraChartsInfo}
                        currentIteration={currentIteration}
                        setModalData={setModalData}
                        setShowModal={setShowModal}
                        currentProjectId={props.currentProjectId}
                      />
                    </div>
                  </div>
                )}
              <div id="defectsvsReleaseItems" className="col col-5 mb-4">
                <div id="defect-release-root">
                  <DefectsVsReleaseItemsChart
                    projectTestSource={projectDetails.projectTestSource}
                    jiraChartsInfo={jiraChartsInfo}
                    currentIteration={currentIteration}
                    setModalData={setModalData}
                    setShowModal={setShowModal}
                    currentProjectId={props.currentProjectId}
                    isADO={currentProjectSourceOfInformation}
                  />
                </div>
              </div>
              <div id="targetedOpenDefectsBySprint" className="col col-5 mb-4">
                <div id="defect-targeted-open-root">
                  <TargetedOpenDefectsBySprintChart
                    jiraChartsInfo={jiraChartsInfo}
                    currentIteration={currentIteration}
                    setModalData={setModalData}
                    setShowModal={setShowModal}
                    currentProjectId={props.currentProjectId}
                    isADO={currentProjectSourceOfInformation}
                  />
                </div>
              </div>
              <div id="itemsWithResolvedStatusChart" className="col col-5 mb-4">
                <div id="items-with-resolved-status-chart-root">
                  {jiraChartsInfo && (
                    <ItemsWithResolvedStatusChart
                      jiraChartsInfo={jiraChartsInfo}
                      currentIteration={currentIteration}
                      setModalData={setModalData}
                      setShowModal={setShowModal}
                      currentProjectId={props.currentProjectId}
                      isADO={currentProjectSourceOfInformation}
                    />
                  )}
                </div>
              </div>
              <div
                id="resolvedAndAssignedItemsChart"
                className="col col-5 mb-4"
              >
                <div id="resolved-and-assigned-items-chart-root">
                  <ResolvedAndAssignedItemsChart
                    jiraChartsInfo={jiraChartsInfo}
                    currentIteration={currentIteration}
                    setModalData={setModalData}
                    setShowModal={setShowModal}
                    currentProjectId={props.currentProjectId}
                    isADO={currentProjectSourceOfInformation}
                  />
                </div>
              </div>
              <div id="OpenIssueBacklogChart" className="col col-5 mb-4">
                <div id="open-issue-backlog-chart">
                  <OpenIssueBacklogChart
                    currentIteration={currentIteration}
                    token={props.token}
                    isADO={currentProjectSourceOfInformation}
                    issueBackLogInfo={
                      currentProjectSourceOfInformation === "ADO"
                        ? jiraChartsInfo?.openBacklogInfo?.data
                            ?.openIssueStatMap
                        : openBacklogInfo
                    }
                    issues={
                      currentProjectSourceOfInformation === "ADO"
                        ? jiraChartsInfo?.openBacklogInfo?.data?.issues
                        : issues
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {DataSource.ADO === currentProjectSourceOfInformation ? (
            <div></div>
          ) : (
            <div
              id="main_container_2"
              className="bg-light container-fluid pt-3 pb-3"
            >
              <h4 className="ms-4 text-center mb-2">
                Defects per Story per Sprints
              </h4>
              <div className="row ms-3 row-cols-auto justify-content-around">
                <div
                  id="defects-per-story-per-sprint"
                  className="col col-11 mt-4 mb-4"
                >
                  <div id="defects-per-story-per-sprint-table">
                    <DefectsPerStoryPerSprintChart
                      dataForTable={defectsPerStoryInfo}
                      setModalData={setModalData}
                      setShowModal={setShowModal}
                      isADO={currentProjectSourceOfInformation}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
