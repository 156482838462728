import React, { useState, useRef } from "react";
import { Chart } from "react-google-charts";

const ResolvedAndAssignedItemsChart = (props) => {
  const [resolvedAndAssignedItemsData, setItemsWithResolvedStatusData] =
    useState(
      (props.isADO === "ADO"
        ? props.jiraChartsInfo?.resolvedAndAssignedItems?.data?.statMap
        : props.jiraChartsInfo?.resolvedAndAssignedItems?.statMap) || {}
    );

  let issueMap = {};
  if (props.isADO === "ADO") {
    issueMap = props.jiraChartsInfo?.resolvedAndAssignedItems?.data?.issues;
  } else {
    issueMap = props?.jiraChartsInfo?.resolvedAndAssignedItems?.issues;
  }

  const currentIterationInfoRef = useRef();
  const currentDataTableRef = useRef();
  let currentIterationRef = null;
  if (props.isADO === "ADO") {
    currentIterationRef =
      props.currentIteration === "Overall"
        ? resolvedAndAssignedItemsData[0]
        : resolvedAndAssignedItemsData[props.currentIteration];
  } else {
    currentIterationRef =
      props.currentIteration === "Overall"
        ? resolvedAndAssignedItemsData[0]
        : resolvedAndAssignedItemsData[props.currentIteration];
  }

  const dataTable = [["Items", "Quantity"]];
  let resolvedAndAssignedItemsHeaders = Object.keys(currentIterationRef || {});
  currentIterationInfoRef.current = currentIterationRef;
  currentDataTableRef.current = dataTable;

  let quantityPerKey = {};
  for (const key in currentIterationRef) {
    const elements = currentIterationRef[key];
    const totalElements = Object.values(elements).reduce(
      (total, arr) => total + arr.length,
      0
    );
    quantityPerKey[key] = totalElements;
  }

  resolvedAndAssignedItemsHeaders.forEach((item) => {
    dataTable.push([item, quantityPerKey[item]]);
  });

  const chartEvents = [
    {
      eventName: "ready",
      callback: ({ chartWrapper, google }) => {
        var handler = async function () {
          const chart = chartWrapper.getChart();
          var selection = chart.getSelection();
          if (!selection || selection.length < 1) {
            return;
          }
          var row = selection[0].row + 1;
          let selectedSeverity = currentDataTableRef.current[row][0];

          var defectTitles = [
            "Issue ID",
            "Issue Summary",
            "Issue Type",
            "Status",
            "Resolution",
            "Severity",
            "Assignee",
          ];
          var defectsIdsArray =
            currentIterationInfoRef.current?.[selectedSeverity];
          defectsIdsArray = defectsIdsArray ? defectsIdsArray : [];
          var defectsInfoArray = [];

          const propertiesIdsArray = Object.keys(defectsIdsArray);
          const newIdsArray = [];

          propertiesIdsArray.forEach((property) => {
            newIdsArray.push(...defectsIdsArray[property]);
          });

          newIdsArray.forEach((key) => {
            issueMap.forEach((issue) => {
              if (issue.issueID === key) {
                defectsInfoArray.push(issue);
              }
            });
          });

          defectsInfoArray.forEach((issue) => {
            if (issue.lastUpdDate) {
              delete issue.lastUpdDate;
            }
          });

          props.setModalData({
            title: `Resolved Items : ${currentDataTableRef.current[row][0]}`,
            projectId: props.currentProjectId,
            createdDt: null,
            releaseName: null,
            status: null,
            severity: currentDataTableRef.current[row][0],
            tableTitles: defectTitles,
            defects: defectsInfoArray,
            chart: "resolvedAndAssignedItems",
          });
          if (defectsInfoArray.length < 1) {
            props.setShowModal(false);
          } else {
            props.setShowModal(true);
          }
        };

        google.visualization.events.addListener(
          chartWrapper.getChart(),
          "select",
          () => handler()
        );
      },
    },
  ];

  const severitiesColors = {
    Open: "#dc3a12",
    Closed: "green",
  };
  let slicesColors = {};

  dataTable.forEach((row, index) => {
    if (index !== 0) {
      slicesColors[index - 1] = { color: severitiesColors[row[0]] };
    }
  });

  const options = {
    title: "Resolved and Assigned Items",
    titleTextStyle: { fontSize: "16" },
    chartArea: { left: 70, top: 50, width: "100%", height: "100%" },
    is3D: true,
    pieSliceTextStyle: {
      fontSize: 11,
    },
    slices: slicesColors,
  };

  return (
    <Chart
      chartType="PieChart"
      width="100%"
      height="400px"
      data={dataTable}
      options={options}
      chartEvents={chartEvents}
    />
  );
};

export default ResolvedAndAssignedItemsChart;
