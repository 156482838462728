import React, { useEffect, useState } from "react";
import Projects from "../../services/Projects";
import Multiselect from "multiselect-react-dropdown";
import useUserInfo from "../hooks/useUserInfo";
import useProjects from "../hooks/useProjects";
import { MultiSelect } from "react-multi-select-component";
import { useAppContext } from "../../contexts/AppContext";
import "../css/bootstrap.min.css";

async function getProjectTypes(token) {
  try {
    return await Projects.getProjectTypes(token);
  } catch (err) {
    return {
      message: "Project Types list was not received! (" + err.message + ")",
    };
  }
}

function ProjectTypes({
  props,
  handleChange,
  setErrorMessage,
  placeholder,
  selectedProgram,
}) {
  const { projectTypes, setProjectTypes } = useProjects();
  const [curentProjectType, setCurentProjectType] = useState();
  const [projectTypesForMultiSelect, setProjectTypesForMultiSelect] = useState(
    []
  );
  const [selectedMultiProjectType, setSelectedMultiProjectType] = useState([]);
  const [projectTypesForNewMultiSelect, setProjectTypesForNewMultiSelect] =
    useState([]);
  const [selectedNewMultiProjectType, setSelectedNewMultiProjectType] =
    useState([]);
  const { loggedUser } = useUserInfo({});
  const {
    selectedProjectTypeId: selectedProjectType,
    addNewProjectFlag: isNewProject,
    currentSelectedProjectTypeId: current,
    selectedProjectTypeId,
    dispatch,
    currentProjectDetails: projectDetails,
  } = useAppContext();

  const handleProjectTypes = async (e) => {
    if (!projectTypes || Object.entries(projectTypes).length === 0) {
      const response = await getProjectTypes(props.token);
      if (response.message) {
        setErrorMessage(response.message);
        setProjectTypes([]);
      } else {
        if (!response || response.length < 1) {
          setErrorMessage("Projects types list is empty!");
        }
        setProjectTypes(response);
        setCurentProjectType("All");
      }
    }
  };

  useEffect(() => {
    handleProjectTypes();
  }, []);

  useEffect(() => {
    if (!projectTypes || Object.entries(projectTypes).length === 0) {
      return;
    }
    if (selectedProjectType) {
      setCurentProjectType(selectedProjectType);
    } else {
      setCurentProjectType("All");
    }
  }, [projectTypes]);

  useEffect(() => {
    if (!projectTypes || Object.entries(projectTypes).length === 0) {
      return () => {};
    }

    if (props.style === "multy" && projectTypesForMultiSelect.length < 1) {
      let tmp = projectTypes;
      if (!props.notAll) {
        tmp.push({
          projectTypeId: 0,
          projectTypeName: "All",
        });
        setSelectedMultiProjectType([
          {
            projectTypeId: 0,
            projectTypeName: "All",
          },
        ]);
      } else {
        setSelectedMultiProjectType([]);
      }

      tmp.sort(function (a, b) {
        var x = a.projectTypeId;
        var y = b.projectTypeId;
        return x < y ? -1 : x > y ? 1 : 0;
      });
      setProjectTypesForMultiSelect(tmp);
    }

    //for New MultySelect
    if (
      props.style === "new_multy" &&
      projectTypesForNewMultiSelect.length < 1
    ) {
      setSelectedNewMultiProjectType([]);
      let tmp = projectTypes;
      tmp.sort(function (a, b) {
        var x = a.projectTypeId;
        var y = b.projectTypeId;
        return x < y ? -1 : x > y ? 1 : 0;
      });
      let options = tmp.map((item) => {
        return {
          label: item.projectTypeName,
          value: item.projectTypeId,
          projectTypeId: item.projectTypeId,
          projectTypeName: item.projectTypeName,
        };
      });

      setProjectTypesForNewMultiSelect(options);
    }
  }, [projectTypes]);

  const getDefaultProgramValue = (project, index) => {
    let selected;

    if (selectedProgram && projectDetails !== null) {
      if (selectedProjectType !== "All") {
        selected = project.projectTypeId === projectDetails.projectTypeId;
      }
    } else {
      selected =
        '"' + project.projectTypeId.toString() + '"' === selectedProjectType;
    }

    if (isNewProject && index === 0 && current === selectedProjectType) {
      let selectedProjectTypeIdValue = selectedProjectTypeId;

      if (selectedProjectTypeIdValue === "All") {
        dispatch({ type: "EDIT_CURRENT_SELECTED_PROJECT_TYPE_ID", value: 1 });
        return;
      } else {
        let findProjectId = projectTypes.find((project) => {
          return project.projectTypeId == selectedProjectTypeIdValue;
        });
        dispatch({
          tyope: "EDIT_CURRENT_SELECTED_PROJECT_TYPE_ID",
          value: findProjectId?.projectTypeId,
        });
        return;
      }
    }

    return selected;
  };

  if (props.style && props.style === "multy") {
    return (
      <Multiselect
        showArrow
        placeholder={placeholder ? placeholder : "Select Project Type"}
        options={projectTypesForMultiSelect ? projectTypesForMultiSelect : []}
        onSelect={handleChange}
        isObject={true}
        selectedValues={
          selectedMultiProjectType ? selectedMultiProjectType : []
        }
        keepSearchTerm={true}
        hidePlaceholder={true}
        avoidHighlightFirstOption={true}
        singleSelect={
          props.singleSelect !== undefined ? props.singleSelect : true
        }
        displayValue={"projectTypeName"}
        disabled={!!props.disabled}
        style={props.styleValue ? props.styleValue : {}}
      />
    );
  }

  //new MultySelect (to use in EDA)
  if (props.style && props.style === "new_multy") {
    return (
      <MultiSelect
        placeholder="Select Project Type"
        options={projectTypesForNewMultiSelect}
        onChange={(selected) => {
          setSelectedNewMultiProjectType(selected);
          handleChange(selected);
        }}
        value={selectedNewMultiProjectType}
        hasSelectAll={props.notAll ? false : true}
        disabled={!!props.disabled}
        closeOnChangedValue={true}
        multiple={false}
      />
    );
  }

  return (
    <select
      id="projectTypes"
      className="form-select-sm ms-4"
      aria-label="Select Project Type"
      onChange={handleChange}
      disabled={!!props.disabled}
    >
      {!!!props.notAll && <option value="All">All</option>}
      {projectTypes?.length &&
        projectTypes.map((projectType, index) => {
          let isNewProject = localStorage.getItem("addNewProjectFlag");

          if (props.decreaseProjectTypes && projectDetails === null) {
            if (isNewProject) {
              return (
                <option
                  value={projectType.projectTypeId + ""}
                  key={index}
                  selected={getDefaultProgramValue(
                    projectType,
                    loggedUser.projectTypes?.indexOf(projectType.projectTypeId)
                  )}
                  disabled={
                    loggedUser.projectTypes?.indexOf(
                      projectType.projectTypeId
                    ) === -1
                  }
                  style={
                    loggedUser.projectTypes?.indexOf(
                      projectType.projectTypeId
                    ) !== -1
                      ? { background: "none" }
                      : { background: "#C2C2C2" }
                  }
                >
                  {projectType.projectTypeName}
                </option>
              );
            }
          } else {
            return (
              <option
                value={projectType.projectTypeId + ""}
                key={index}
                selected={getDefaultProgramValue(projectType)}
                disabled={
                  loggedUser.projectTypes?.indexOf(
                    projectType.projectTypeId
                  ) === -1
                }
                style={
                  loggedUser.projectTypes?.indexOf(
                    projectType.projectTypeId
                  ) !== -1
                    ? { background: "none" }
                    : { background: "#C2C2C2" }
                }
              >
                {projectType.projectTypeName}
              </option>
            );
          }
        })}
    </select>
  );
}

export default ProjectTypes;
