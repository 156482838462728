import React, { useEffect, useState, createRef } from "react";
import Multiselect from "multiselect-react-dropdown";
import TrashButton from "./TrashButton";
import PlusButton from "./PlusButton";
import Qtest from "../../services/Qtest";
import ErrorMessage from "../common/ErrorMessage";
import DatePicker from "react-datepicker";
import useProjectDetails from "../hooks/useProjectDetails";
import useQtestInfo from "../hooks/useQtestInfo";
import "font-awesome/css/font-awesome.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "../css/bootstrap.min.css";
import "../css/custom.min.css";
import "rsuite/dist/rsuite.min.css";
import { useAppContext } from "../../contexts/AppContext";

async function getTestToolProjects(token) {
  try {
    return await Qtest.getProjects(token);
  } catch (err) {
    return {
      message:
        "Error getting qTest projects (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

async function getReleases(token, projectId) {
  try {
    return await Qtest.getReleases(token, projectId);
  } catch (err) {
    return {
      message:
        "Error getting qTest releases (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

async function getTestCycles(token, projectId, releaseIds) {
  try {
    return await Qtest.getTestCycles(token, projectId, releaseIds);
  } catch (err) {
    return {
      message:
        "Error getting qTest test cycles (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

function TestManagementTool(props) {
  const { currentProjectDetails: projectDetails } = useAppContext();

  const {
    testToolProjects,
    setTestToolProjects,
    testProjectReleasesInfo,
    setTestProjectReleasesInfo,
  } = useQtestInfo();
  const [currentQTestProject, setCurrentQTestProject] = useState();
  const [currentProjectReleases, setCurrentProjectReleases] = useState([]);
  const [currentProjectReleasesWithNames, setCurrentProjectReleasesWithNames] =
    useState([]);
  const [currentTestCycles, setCurrentTestCycles] = useState([]);
  const [currentTestCyclesForMultySelect, setCurrentTestCyclesForMultySelect] =
    useState([]);
  const [selectedReleases, setSelectedReleases] = useState([]);
  const [selectedReleasesWithNames, setSelectedReleasesWithNames] = useState(
    []
  );
  const [iterations, setIterations] = useState([]);
  const [toOpenSprints, setToOpenSprints] = useState({});
  const [dropDownRefs, setDropDownRefs] = useState([]);
  const [currentSprintSearchValue, setCurrentSprintSearchValue] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [isAllPopulated, setIsAllPopulated] = useState(false);
  const [isIterationsPopulated, setIsIterationsPopulated] = useState(false);

  useEffect(() => {
    props.props.handleTestToolIterationsChange(iterations);
  }, [iterations]);

  useEffect(() => {
    setDropDownRefs((dropDownRefs) =>
      Array(iterations.length)
        .fill()
        .map((_, i) => dropDownRefs[i] || createRef())
    );
  }, [iterations.length]);

  useEffect(() => {
    let i = 0;

    Object.keys(toOpenSprints).forEach(function (key) {
      var value = toOpenSprints[key];

      if (value) {
        dropDownRefs[i].current.toggelOptionList();

        let searchValue = currentSprintSearchValue[key];
        if (searchValue) {
          dropDownRefs[i].current.state.inputValue = searchValue;
        }
      }
      i++;
    });
  }, []);

  const sortIterationsByStartDate = (iterationsList) => {
    iterationsList.sort((a, b) =>
      a.startDate > b.startDate ? 1 : b.startDate > a.startDate ? -1 : 0
    );
    return iterationsList;
  };

  const handleTestToolProjects = async (e) => {
    var _qTestProjectId,
      _qTestReleasesInfo = [];
    //set correct qTest project id
    //-----------------------------
    //if it is Add New Project page and the list of all qTest projects is empty, get info from the server
    if (
      (!testToolProjects || Object.entries(testToolProjects).length === 0) &&
      !errorMessage
    ) {
      setLoading(true);
      const response = await getTestToolProjects(props.token);
      setLoading(false);
      if (response.message) {
        setErrorMessage(response.message);
        setTestToolProjects([]);
      } else {
        if (!response || response.length < 1) {
          setErrorMessage("qTest projects types list is empty!");
        }
        setTestToolProjects(response);
        //if it is Edit page for the existed selected LP project
        if (
          projectDetails?.lpQtestRelations &&
          projectDetails?.lpQtestRelations.length > 1 &&
          projectDetails?.lpQtestRelations[0]?.qtestProjectId
        ) {
          //set current qTest project id from the selected existed LP project's properties
          props.props.saveCurrentTestToolProjectId(
            projectDetails.lpQtestRelations[0].qtestProjectId
          );
          setCurrentQTestProject(
            projectDetails.lpQtestRelations[0].qtestProjectId
          );
          _qTestProjectId = projectDetails.lpQtestRelations[0].qtestProjectId;
        }
        //if it is Add New Project page, set the current qTest project with the first project's id from the response
        if (
          (!projectDetails?.lpQtestRelations ||
            !projectDetails?.lpQtestRelations[0]?.qtestProjectId) &&
          response[0]?.id
        ) {
          props.props.saveCurrentTestToolProjectId(response[0].id);
          setCurrentQTestProject(response[0].id);
          _qTestProjectId = response[0].id;
        }
      }
    } else {
      //for Add New Project if the list of qTest projects is not empty
      if (
        !projectDetails?.lpQtestRelations ||
        !projectDetails?.lpQtestRelations[0]?.qtestProjectId
      ) {
        if (testToolProjects[0]?.id) {
          props.props.saveCurrentTestToolProjectId(testToolProjects[0].id);
          setCurrentQTestProject(testToolProjects[0].id);
          _qTestProjectId = testToolProjects[0].id;
        }
      } else {
        //set current qTest project's id for the existed LP project
        props.props.saveCurrentTestToolProjectId(
          projectDetails &&
            projectDetails.lpQtestRelations &&
            projectDetails.lpQtestRelations.length &&
            projectDetails.lpQtestRelations[0].qtestProjectId
        );
        setCurrentQTestProject(
          projectDetails &&
            projectDetails.lpQtestRelations &&
            projectDetails.lpQtestRelations.length &&
            projectDetails.lpQtestRelations[0].qtestProjectId
        );
        _qTestProjectId =
          projectDetails &&
          projectDetails.lpQtestRelations &&
          projectDetails.lpQtestRelations.length &&
          projectDetails.lpQtestRelations[0].qtestProjectId;
      }
    }
    //-----------------------------
    // get qTest releases for the needed qTest project
    if (_qTestProjectId) {
      setLoading(true);

      const response = await getReleases(props.token, _qTestProjectId);
      setLoading(false);
      if (response.message) {
        setErrorMessage(response.message);
        setTestProjectReleasesInfo([]);
        saveReleasesInfoFromResponse();
      } else {
        setTestProjectReleasesInfo(response);
        setSelectedReleases([]);
        setSelectedReleasesWithNames([]);
        saveReleasesInfoFromResponse(response);
        _qTestReleasesInfo = response.slice();
      }
    }

    if (projectDetails?.lpIterations) {
      setIterations(sortIterationsByStartDate(projectDetails?.lpIterations));

      let tmpToOpenIterations = {};
      projectDetails.lpIterations.map((item, index) => {
        tmpToOpenIterations[item["id"]] = false;
        return item;
      });
      setToOpenSprints(tmpToOpenIterations);
    }
    if (projectDetails?.lpQtestRelations) {
      let tmp = [],
        tmpWithNames = [];
      for (let relation of projectDetails.lpQtestRelations) {
        tmp.push(relation.qtestReleaseId);
        //get release name from the list of all releases
        let relName = _qTestReleasesInfo.filter((item) => {
          return item.id === relation.qtestReleaseId;
        });
        relName =
          relName[0] && relName[0].name
            ? relName[0].name
            : relation.qtestReleaseName;
        tmpWithNames.push({
          id: relation.qtestReleaseId,
          name: relName,
        });
      }

      setSelectedReleases(tmp);
      setSelectedReleasesWithNames(tmpWithNames);
      if (tmpWithNames?.length > 0) {
        setIsAllPopulated(true);
      } else {
        setIsAllPopulated(false);
      }

      //get test cycles according to the projects' releases
      setLoading(true);
      const response = await getTestCycles(
        props.token,
        projectDetails?.lpQtestRelations &&
          projectDetails?.lpQtestRelations.length &&
          projectDetails?.lpQtestRelations[0].qtestProjectId,
        tmpWithNames
      );
      setLoading(false);
      if (response.message) {
        setErrorMessage(response.message);
        setCurrentTestCycles([]);
        setCurrentTestCyclesForMultySelect([]);
      } else {
        setCurrentTestCycles(response);
        setCurrentTestCyclesForMultySelect(response);

        if (projectDetails?.lpIterations) {
          let tmpIterations = JSON.parse(
            JSON.stringify(projectDetails.lpIterations)
          );

          for (let iterationObj of tmpIterations) {
            if (!iterationObj.iterationCyclesForMultySelect) {
              iterationObj.iterationCyclesForMultySelect = [];
            }

            iterationObj.iterationCyclesForMultySelect = response.filter(
              (respItem) => {
                const resSplit = respItem.id.split(":-:");
                if (resSplit.length < 1) return false;

                for (let obj of iterationObj.iterationCycles) {
                  if (obj.includes(resSplit[0])) {
                    return true;
                  }
                }
                return false;
              }
            );

            if (iterationObj.startDate) {
              iterationObj.startDateTimeStamp =
                Date.parse(iterationObj.startDate) +
                new Date(iterationObj.startDate).getTimezoneOffset() * 60000;
            }
            if (iterationObj.endDate) {
              iterationObj.endDateTimeStamp =
                Date.parse(iterationObj.endDate) +
                new Date(iterationObj.endDate).getTimezoneOffset() * 60000;
            }
          }

          setIterations(tmpIterations);
        }
      }
    }
  };

  useEffect(() => {
    handleTestToolProjects();
  }, [testToolProjects]);

  useEffect(() => {
    //check if list of iteration is not empty
    //and that at least one iteration has not empty name
    let result = iterations.some((item) => {
      if (item.iterationName) return true;
      return false;
    });
    setIsIterationsPopulated(result);
  }, [iterations]);

  const saveReleasesInfoFromResponse = (response) => {
    if (!response) {
      setCurrentProjectReleases([]);
      setCurrentProjectReleasesWithNames([]);
      return;
    }
    let tmp = [],
      tmpWithNames = [];
    for (let release of response) {
      tmp.push(release.id);
      tmpWithNames.push({
        id: release.id,
        name: release.name,
      });
    }
    setCurrentProjectReleases(tmp);
    setCurrentProjectReleasesWithNames(tmpWithNames);
  };

  const handleProjectChange = async (event) => {
    setCurrentQTestProject(event.target.value);

    props.props.saveTestToolProjectChange(event);

    setLoading(true);
    setCurrentTestCycles([]);
    setCurrentTestCyclesForMultySelect([]);
    setLoading(true);
    const response = await getReleases(props.token, event.target.value);
    setLoading(false);

    if (response.message) {
      setErrorMessage(response.message);
      setTestProjectReleasesInfo([]);
      saveReleasesInfoFromResponse();
    } else {
      setTestProjectReleasesInfo(response);
      setSelectedReleases([]);
      setSelectedReleasesWithNames([]);
      saveReleasesInfoFromResponse(response);
    }

    handleMultySelectReleasesChange([]);
  };

  const handleMultySelectReleasesChange = async (
    selectedList,
    selectedItem,
    id
  ) => {
    setErrorMessage();
    setCurrentTestCycles([]);
    setCurrentTestCyclesForMultySelect([]);

    let options = [];
    let values = Array.from(selectedList, (item) => {
      options.push({ value: item.id, releaseName: item.name });
      return Number(item.id);
    });
    props.props.handleTestToolReleasesChange({
      target: { selectedOptions: options },
    });

    setSelectedReleases(values);
    setSelectedReleasesWithNames(selectedList);

    if (selectedList?.length > 0) {
      setIsAllPopulated(true);
    } else {
      setIsAllPopulated(false);
    }

    var tmpIterations = JSON.parse(JSON.stringify(iterations));

    if (selectedList.length < 1) {
      setCurrentTestCyclesForMultySelect([]);
      for (let key in tmpIterations) {
        tmpIterations[key].iterationCycles = [];
        tmpIterations[key].iterationCyclesForMultySelect = [];
      }
    } else {
      setLoading(true);
      const response = await getTestCycles(
        props.token,
        currentQTestProject,
        selectedList
      );
      setLoading(false);

      if (response.message) {
        setErrorMessage(response.message);
        setCurrentTestCycles([]);
        setCurrentTestCyclesForMultySelect([]);
        for (let key in tmpIterations) {
          tmpIterations[key].iterationCycles = [];
          tmpIterations[key].iterationCyclesForMultySelect = [];
        }
      } else {
        setCurrentTestCycles(response);
        setCurrentTestCyclesForMultySelect(response);

        for (let key in tmpIterations) {
          tmpIterations[key].iterationCycles = tmpIterations[
            key
          ].iterationCycles.filter((item) => {
            for (let respItem of response) {
              if (respItem.id === item) {
                return true;
              }
            }
            return false;
          });
          if (!tmpIterations[key].iterationCyclesForMultySelect) {
            tmpIterations[key].iterationCyclesForMultySelect = [];
          }
          tmpIterations[key].iterationCyclesForMultySelect = tmpIterations[
            key
          ].iterationCyclesForMultySelect.filter((item) => {
            for (let respItem of response) {
              if (respItem.id === item.id) {
                return true;
              }
            }
            return false;
          });
        }
      }
    }
    let toStore = JSON.parse(JSON.stringify(tmpIterations));
    toStore = toStore.filter((item) => {
      return item.iterationName && item.iterationCycles.length > 0;
    });
    setIterations(tmpIterations);
    props.props.handleTestToolIterationsChange(toStore);
  };

  const handleMultySelectSprintTestCyclesChange = async (
    selectedList,
    selectedItem,
    iterationId,
    index
  ) => {
    let values = Array.from(selectedList, (item) => item.id);
    let tmp = JSON.parse(JSON.stringify(iterations));

    for (let key in tmp) {
      if (tmp[key].id === iterationId) {
        tmp[key].iterationCycles = values.slice();
        tmp[key].iterationCyclesForMultySelect = selectedList.slice();
      }
    }
    let toStore = tmp.slice();
    toStore = toStore.filter((item) => {
      return item.iterationName && item.iterationCycles.length > 0;
    });
    setIterations(tmp);
    props.props.handleTestToolIterationsChange(toStore);

    let tmpToOpenSprints = JSON.parse(JSON.stringify(toOpenSprints));
    let tmpSearchValues = {};
    let searchValue = dropDownRefs[index].current.searchBox.current.value;
    Object.keys(tmpToOpenSprints).forEach((key) => {
      if (key == iterationId) {
        tmpToOpenSprints[key] = true;
        tmpSearchValues[key] = searchValue;
      } else {
        tmpToOpenSprints[key] = false;
        tmpSearchValues[key] = "";
      }
    });

    setToOpenSprints(tmpToOpenSprints);
    setCurrentSprintSearchValue(tmpSearchValues);
  };

  const handleSprintNameChange = (event, id) => {
    let tmp = JSON.parse(JSON.stringify(iterations));
    for (let key in tmp) {
      if (tmp[key].id === id) {
        tmp[key].iterationName = event.target.value;
      }
    }
    setIterations(tmp);
    props.props.handleTestToolIterationsChange(tmp);
  };

  const handleSprintStartDateChange = (date, id) => {
    for (let key in iterations) {
      if (iterations[key].id === id) {
        iterations[key].startDate = date
          ? date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate()
          : null;
        iterations[key].startDateTimeStamp = date ? date.getTime() : 0;
        break;
      }
    }
    setIterations(iterations.slice());
    props.props.handleTestToolIterationsChange(iterations);
  };

  const handleSprintEndDateChange = (date, id) => {
    for (let key in iterations) {
      if (iterations[key].id === id) {
        iterations[key].endDate = date
          ? date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate()
          : null;
        iterations[key].endDateTimeStamp = date ? date.getTime() : 0;
        break;
      }
    }

    setIterations(iterations.slice());
    props.props.handleTestToolIterationsChange(iterations);
  };

  const handlePlusForTestProjectSprints = () => {
    let tmp = JSON.parse(JSON.stringify(iterations));
    let ids = tmp.map((item) => {
      return item.id;
    });
    ids.sort(function (a, b) {
      return a - b;
    });
    let id = ids.length > 0 ? ids[ids.length - 1] + 1 : 1;
    tmp.push({
      id: id,
      iterationName: "",
      iterationCycles: [],
      projectId: "",
      startDate: "",
      endDate: "",
    });
    setIterations(tmp);
  };

  const handleDeleteForTestProjectSprints = (id) => {
    let tmp = JSON.parse(JSON.stringify(iterations));
    tmp = tmp.filter((item, index) => {
      return item.id !== id;
    });

    setIterations(tmp);
    props.props.handleTestToolIterationsChange(tmp);
  };

  const getDate = (date) => {
    const initialDate = new Date(date);
    initialDate.setDate(initialDate.getDate() + 1);

    return initialDate;
  };

  return (
    <div id="test-tool-container">
      <ErrorMessage errorMessage={errorMessage} />
      {
        <>
          <div className="row mb-4">
            <div className="col md-6">
              <label htmlFor="testTool" className="form-label fs-7 fw-bold">
                <span style={isAllPopulated ? {} : { color: "red" }}>* </span>
                Test Management Tool:
              </label>
              {loading && (
                <div
                  className="spinner-border text-secondary spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
              <div
                id="testTool"
                className="btn-toolbar  "
                role="toolbar"
                aria-label="Toolbar with button groups"
              >
                <div role="group" className="btn-group btn-group-sm">
                  <input
                    type="radio"
                    id="qtest"
                    name="options"
                    className="btn-check"
                    value=""
                    autoComplete="off"
                    defaultChecked={
                      projectDetails?.projectTestSource === "qtest"
                    }
                    onChange={() => {}}
                    disabled
                  />
                  <label htmlFor="qtest" className="btn btn-outline-secondary">
                    qTest
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            className="row mb-4"
            style={
              loading
                ? {
                    pointerEvents: "none",
                    opacity: "0.4",
                  }
                : {}
            }
          >
            <div className="col md-6">
              <ul className="list-group input-group-sm">
                <div className="input-group input-group-sm">
                  <li className="list-group-item">
                    <div className="input-group input-group-sm mb-2">
                      <span className="input-group-text" id="basic-addon3">
                        qTest Project:
                      </span>
                      <select
                        id="testToolProjects"
                        className="form-select"
                        aria-label="Select qTest Project"
                        value={
                          projectDetails?.lpQtestRelations &&
                          projectDetails?.lpQtestRelations[0] &&
                          projectDetails?.lpQtestRelations[0].qtestProjectId
                        }
                        onChange={handleProjectChange}
                        disabled={!!props.disabled}
                      >
                        {testToolProjects &&
                          testToolProjects.map((project, index) => {
                            return (
                              <option value={project.id} key={index}>
                                {project.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="input-group input-group-sm">
                      <span className="input-group-text" id="basic-addon3">
                        qTest Releases:
                      </span>
                      {testProjectReleasesInfo && (
                        <div style={{ width: "100%", maxWidth: "385px" }}>
                          <Multiselect
                            id="releases"
                            showArrow
                            options={currentProjectReleasesWithNames}
                            showCheckbox={true}
                            onSelect={(values) => {
                              handleMultySelectReleasesChange(values);
                              setIsAllPopulated(true);
                            }}
                            onRemove={(values) => {
                              handleMultySelectReleasesChange(values);
                              setIsAllPopulated(false);
                            }}
                            placeholder="Select Release(s)"
                            isObject={true}
                            selectedValues={selectedReleasesWithNames}
                            keepSearchTerm={true}
                            hidePlaceholder={true}
                            avoidHighlightFirstOption={true}
                            displayValue="name"
                            disable={!!props.disabled}
                            style={{
                              inputField: {
                                margin: "0px",
                                padding: "0px",
                                textDecoration: "none",
                              },
                              multiselectContainer: {
                                margin: "0px",
                                padding: "0px",
                              },
                              searchBox: { margin: "0px", padding: "3px" },
                            }}
                            // selectionLimit={1} To Paul's request for 1st PROD release
                          />
                        </div>
                      )}
                    </div>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </>
      }
      <hr
        style={{ visibility: "visible" }}
        className="bg-secondary border-2 border-top border-secondary"
      ></hr>
      <div className="row mb-4">
        <div className="col md-6">
          <label htmlFor="testReleases" className="form-label fs-7 fw-bold">
            {props.isActive ? (
              <span style={isIterationsPopulated ? {} : { color: "red" }}>
                *{" "}
              </span>
            ) : null}
            Sprints:
          </label>
          <table className="table table-bordered table-sm ">
            <thead>
              <tr
                style={{ backgroundColor: "#e9ecef", color: "#212529" }}
                className="border-bottom-0"
              >
                <th scope="col" className="fw-normal " style={{ width: "20%" }}>
                  Name
                </th>
                <th scope="col" className="fw-normal">
                  Test Cycles/Suites
                </th>
                <th scope="col" className="fw-normal" style={{ width: "20%" }}>
                  Start Date
                </th>
                <th scope="col" className="fw-normal" style={{ width: "20%" }}>
                  End Date
                </th>
                <th scope="col" className="fw-normal" style={{ width: "5%" }}>
                  <PlusButton
                    disabled={!!props.disabled}
                    onClick={handlePlusForTestProjectSprints}
                  />
                </th>
              </tr>
            </thead>
            {
              <tbody>
                {!iterations
                  ? null
                  : iterations.map((iteration, index) => {
                      return (
                        <tr className="bg-body" key={index}>
                          <td>
                            <input
                              id={"sprint-name-" + index}
                              className="form-control"
                              type="text"
                              value={iteration.iterationName}
                              disabled={!!props.disabled}
                              onChange={(e) =>
                                handleSprintNameChange(e, iteration.id)
                              }
                              autoComplete="off"
                            />
                          </td>
                          <td>
                            <Multiselect
                              id={"sprint-select-" + index}
                              showArrow
                              options={currentTestCyclesForMultySelect}
                              showCheckbox={true}
                              onSelect={(selectedList, selectedItem) => {
                                handleMultySelectSprintTestCyclesChange(
                                  selectedList,
                                  selectedItem,
                                  iteration.id,
                                  index
                                );
                              }}
                              onRemove={(selectedList, selectedItem) =>
                                handleMultySelectSprintTestCyclesChange(
                                  selectedList,
                                  selectedItem,
                                  iteration.id,
                                  index
                                )
                              }
                              placeholder="Select Sprints"
                              isObject={true}
                              selectedValues={
                                iteration?.iterationCyclesForMultySelect
                              }
                              keepSearchTerm={true}
                              hidePlaceholder={true}
                              avoidHighlightFirstOption={true}
                              className="sm"
                              displayValue="name"
                              disable={!!props.disabled}
                              closeOnSelect={false}
                              ref={dropDownRefs[index]}
                            />
                          </td>
                          <td>
                            <DatePicker
                              id={"sprint-start-date-" + index}
                              className="form-control "
                              dateFormat={"yyyy-MM-dd"}
                              selected={iteration?.startDateTimeStamp}
                              disabled={!!props.disabled}
                              onChange={(date) =>
                                handleSprintStartDateChange(date, iteration.id)
                              }
                            ></DatePicker>
                          </td>
                          <td>
                            <DatePicker
                              id={"sprint-end-date-" + index}
                              className="form-control "
                              dateFormat={"yyyy-MM-dd"}
                              selected={iteration?.endDateTimeStamp}
                              disabled={!!props.disabled}
                              onChange={(date) =>
                                handleSprintEndDateChange(date, iteration.id)
                              }
                            ></DatePicker>
                          </td>
                          <td>
                            <TrashButton
                              disabled={!!props.disabled}
                              id={"trash-button-" + index}
                              onClick={() =>
                                handleDeleteForTestProjectSprints(iteration.id)
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            }
          </table>
        </div>
      </div>
    </div>
  );
}

export default TestManagementTool;
