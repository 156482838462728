import { useState } from "react";
import { useAppContext } from "../../contexts/AppContext";

export default function useEditProjectDetails() {
  const { currentEditProjectDetails, dispatch } = useAppContext();
  const getEditProjectDetails = () => {
    /*
    TODO: erase this
    const projectDetailsStr = localStorage.getItem("currentEditProjectDetails");
    */
    if (!currentEditProjectDetails) {
      return {
        projectId: -1,
        lpJiraRelations: [{}],
        lpIterations: [{}],
        lpQtestRelations: [{}],
        projectMilestones: [{}],
      };
    }
    // const projectDetails = projectDetailsStr;
    return currentEditProjectDetails;
  };

  const [projectEditDetails, setEditProjectDetails] = useState(
    getEditProjectDetails()
  );

  const saveEditProjectDetails = (projectDetails) => {
    /*
    TODO: Erase this
    //localStorage.setItem(
    //  "currentEditProjectDetails",
    //  JSON.stringify(projectDetails)
    //);
    */
    dispatch({
      type: "EDIT_CURRENT_EDIT_PROJECT_DETAILS",
      value: projectDetails,
    });
    setEditProjectDetails(projectDetails);
  };

  const clearEditProjectDetails = () => {
    let empty = {
      projectId: -1,
      lpJiraRelations: [{}],
      lpIterations: [{}],
      lpQtestRelations: [{}],
      projectMilestones: [
        {
          id: 1,
          milestone: "",
          startDate: "",
          endDate: "",
          projectId: "",
        },
      ],
      projectTestSource: "qtest",
      projectName: "",
      isActive: false,
      startDate: "",
      endDate: "",
      testPlanLink: "",
    };
    saveEditProjectDetails(empty);
  };

  return {
    setEditProjectDetails: saveEditProjectDetails,
    projectEditDetails: projectEditDetails,
    clearEditProjectDetails,
  };
}
