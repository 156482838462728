import { createContext, useContext, useReducer } from "react";

const AppContext = createContext();

const useAppContext = () => {
  return useContext(AppContext);
};

const initialState = {
  projectTypes: [],
  projects: [],
  selectedProjectTypeId: "All",
  inactiveProjects: [],
  currentSelectedProjectTypeId: 1,
  currentProjectReportInfo: {},
  currentProjectId: "",
  currentProjectDetails: {},
  currentEditProjectDetails: {},
  addNewProjectFlag: false,
  jiraInfo: [],
};

const reducer = (store, action) => {
  switch (action.type) {
    case "EDIT_PROJECT_TYPES": {
      return {
        ...store,
        projectTypes: action.value,
      };
    }
    case "EDIT_PROJECTS": {
      return {
        ...store,
        projects: action.value,
      };
    }
    case "EDIT_SELECTED_PROJECT_TYPE_ID": {
      return {
        ...store,
        selectedProjectTypeId: action.value,
      };
    }
    case "EDIT_INACTIVE_PROJECTS": {
      return {
        ...store,
        inactiveProjects: action.value,
      };
    }
    case "EDIT_CURRENT_SELECTED_PROJECT_TYPE_ID": {
      return {
        ...store,
        currentSelectedProjectTypeId: action.value,
      };
    }

    case "EDIT_CURRENT_PROJECT_REPORT_INFO": {
      return {
        ...store,
        currentProjectReportInfo: action.value,
      };
    }
    case "EDIT_CURRENT_PROJECT_ID": {
      return {
        ...store,
        currentProjectId: action.value,
      };
    }
    case "EDIT_CURRENT_PROJECT_DETAILS": {
      return {
        ...store,
        currentProjectDetails: action.value,
      };
    }
    case "EDIT_CURRENT_EDIT_PROJECT_DETAILS": {
      return {
        ...store,
        currentEditProjectDetails: action.value,
      };
    }
    case "EDIT_ADD_NEW_PROJECT_FLAG": {
      return {
        ...store,
        addNewProjectFlag: action.value,
      };
    }
    case "EDIT_JIRA_INFO": {
      return {
        ...store,
        jiraInfo: action.value,
      };
    }
    default: {
      return store;
    }
  }
};

const AppProvider = ({ children }) => {
  const [store, dispatch] = useReducer(reducer, initialState);
  const storeToProvide = {
    projectTypes: store.projectTypes,
    projects: store.projects,
    selectedProjectTypeId: store.selectedProjectTypeId,
    inactiveProjects: store.inactiveProjects,
    currentSelectedProjectTypeId: store.currentSelectedProjectTypeId,
    currentProjectReportInfo: store.currentProjectReportInfo,
    currentProjectId: store.currentProjectId,
    currentProjectDetails: store.currentProjectDetails,
    addNewProjectFlag: store.addNewProjectFlag,
    jiraInfo: store.jiraInfo,
    dispatch,
  };

  return (
    <AppContext.Provider value={storeToProvide}>{children}</AppContext.Provider>
  );
};

export { AppProvider, useAppContext };
