import React, { useEffect, useState, useRef } from "react";
import ProjectDetailsComponent from "../components/projectDetails/ProjectDetails";
import NavigationTab from "../components/common/NavigationTab";
import ErrorMessage from "../components/common/ErrorMessage";
import TestExecution from "./projectDetails/TestExecution";
import TestDevelopment from "./projectDetails/TestDevelopment";
import Defects from "./projectDetails/Defects";
import SteercoDefects from "./projectDetails/SteercoDefects";
import ProcessCompliance from "./projectDetails/ProcessCompliance";
import RequirementsCoverage from "./projectDetails/RequirementsCoverage";
import RequirementsStatus from "./projectDetails/RequirementsStatus";
import Report from "./projectDetails/Report";
import ReleaseScope from "./projectDetails/ReleaseScope";
import useErrorMessage from "../components/hooks/useErrorMessage";
import useProjects from "../components/hooks/useProjects";
import useProjectDetails from "../components/hooks/useProjectDetails";
import { useAppContext } from "../contexts/AppContext";

import {
  // HashRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "../components/css/bootstrap.min.css";

const ProjectDetails = (props) => {
  const {
    setCurrentProjectId,
    setAddNewProjectFlag,
    currentProjectId,
    addNewProjectFlag,
  } = useProjectDetails();
  const { clearErrorMessage } = useErrorMessage();
  const { projects } = useProjects();
  const [perfMenuSelected, setPerfMenuSelected] = useState("");
  const [toShowPerfDropdown, setToShowPerfDropdown] = useState(false);
  const [currentProjectName, setCurrentProjectName] = useState();
  const perfDropDownMenuRef = useRef(null);
  const { projectId } = useParams();
  const {
    projects: projectsStore,
    currentProjectDetails,
    dispatch,
  } = useAppContext();
  const [isActiveList, setIsActiveList] = useState({
    settings: "active",
    releaseScope: "",
    testcases: "",
    testdevelopment: "",
    defects: "",
    steercodefects: "",
    requirementsStatus: "",
    requirementsCoverage: "",
    performance: "",
    security: "",
    processCompliance: "",
    report: "",
  });

  const history = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === `/project/${projectId}/`) {
      history("settings");
    }
  }, [location.pathname, history]);

  const changeStyle = (tabId) => {
    let newStatuses = {};
    for (let key in isActiveList) {
      if (key === tabId) {
        newStatuses[key] = "active";
      } else {
        newStatuses[key] = "";
      }
    }
    clearErrorMessage();
    setIsActiveList(newStatuses);
  };

  useEffect(() => {
    var url = window.location.href;
    if (projectId === "new") {
      setAddNewProjectFlag(true);
    } else {
      setAddNewProjectFlag(false);
    }

    if (url.indexOf("settings") > 0) {
      changeStyle("settings");
    }
    if (url.indexOf("testcases") > 0) {
      changeStyle("testcases");
    }
    if (url.indexOf("testdevelopment") > 0) {
      changeStyle("testdevelopment");
    }
    if (url.indexOf("defects") > 0) {
      changeStyle("defects");
    }
    if (url.indexOf("steercodefects") > 0) {
      changeStyle("steercodefects");
    }
    if (url.indexOf("requirementsStatus") > 0) {
      changeStyle("requirementsStatus");
    }
    if (url.indexOf("requirementsCoverage") > 0) {
      changeStyle("requirementsCoverage");
    }
    if (url.indexOf("performance") > 0) {
      changeStyle("performance");
    }
    if (url.indexOf("security") > 0) {
      changeStyle("security");
    }
    if (url.indexOf("processCompliance") > 0) {
      changeStyle("processCompliance");
    }
    if (url.indexOf("report") > 0) {
      changeStyle("report");
    }

    let currentId = props.currentProjectId;
    //let res = /project\/(\d+)/g.exec(url);

    // if (!currentId && projectId !== currentId) {
    //   // if (res?.length > 1) {
    //   //currentId = res[1];
    //   props.setCurrentProjectId(projectId);
    //   // }
    // } else {
    //   window.location.href = url.replace("null", projectId);
    // }
    setCurrentProjectId(projectId);
  }, []);
  let projectTestSource = currentProjectDetails?.projectTestSource;

  useEffect(() => {
    let store = projectsStore;
    projectTestSource = currentProjectDetails?.projectTestSource;

    let filteredProject = store.filter(
      (project) => project.projectId === projectId
    );
  }, []);

  useEffect(() => {
    const closePerfMenu = (e) => {
      if (
        perfDropDownMenuRef.current &&
        !perfDropDownMenuRef.current.contains(e.target)
      ) {
        setToShowPerfDropdown(false);
      }
    };
    window.addEventListener("mousedown", closePerfMenu);
    return () => {
      window.removeEventListener("mousedown", closePerfMenu);
    };
  }, []);

  return (
    <div id="project-main" style={{ minHeight: "calc(100vh - 60px)" }}>
      <NavigationTab
        changeStyle={props.navTabChangeStyle}
        currentProjectId={projectId}
      />
      <div id="main_container">
        <div id="main_container_1">
          <div
            id="projects_header_container"
            className="container-fluid pt-3 pb-3"
          >
            <h4 className="ms-4">{currentProjectName}</h4>
            <ErrorMessage />
          </div>
          {/* <Router> */}
          <ul className="nav nav-tabs" id="navTab" role="tablist">
            {!addNewProjectFlag && (
              <li className="nav-item" role="presentation">
                <div
                  className={`nav-link ${isActiveList["settings"]}`}
                  aria-current="page"
                >
                  <Link
                    to={"settings"}
                    className="text-decoration-none"
                    onClick={() => {
                      changeStyle("settings");
                    }}
                  >
                    Project Settings
                  </Link>
                </div>
              </li>
            )}

            {!addNewProjectFlag && (
              <li className="nav-item" role="presentation">
                <div
                  className={`nav-link ${isActiveList["testcases"]}`}
                  aria-current="page"
                >
                  <Link
                    to={`testcases`}
                    className="text-decoration-none"
                    onClick={() => {
                      changeStyle("testcases");
                    }}
                  >
                    Test Execution
                  </Link>
                </div>
              </li>
            )}
            {!addNewProjectFlag &&
              projectTestSource !== "ADO" &&
              projectTestSource !== "" && (
                <li className="nav-item" role="presentation">
                  <div
                    className={`nav-link ${isActiveList["testdevelopment"]}`}
                    aria-current="page"
                  >
                    <Link
                      to={`testdevelopment`}
                      className="text-decoration-none"
                      onClick={() => {
                        changeStyle("testdevelopment");
                      }}
                    >
                      Test Development
                    </Link>
                  </div>
                </li>
              )}
            {!addNewProjectFlag && (
              <li className="nav-item" role="presentation">
                <div className={`nav-link ${isActiveList["defects"]}`}>
                  <Link
                    to={`defects`}
                    className="text-decoration-none"
                    onClick={() => {
                      changeStyle("defects");
                    }}
                  >
                    Defects
                  </Link>
                </div>
              </li>
            )}
            {!addNewProjectFlag && (
              <li className="nav-item" role="presentation">
                <div className={`nav-link ${isActiveList["steercodefects"]}`}>
                  <Link
                    to={`steercodefects`}
                    className="text-decoration-none"
                    onClick={() => {
                      changeStyle("steercodefects");
                    }}
                  >
                    Steerco Defects
                  </Link>
                </div>
              </li>
            )}
            {!addNewProjectFlag &&
              projectTestSource !== "ADO" &&
              projectTestSource !== "" && (
                <li className="nav-item" role="presentation">
                  <div
                    className={`nav-link ${isActiveList["releaseScope"]}`}
                    aria-current="page"
                  >
                    <Link
                      to={`releaseScope`}
                      className="text-decoration-none"
                      onClick={() => {
                        changeStyle("releaseScope");
                      }}
                    >
                      Release Scope
                    </Link>
                  </div>
                </li>
              )}
            {!addNewProjectFlag &&
              projectTestSource !== "ADO" &&
              projectTestSource !== "" && (
                <li className="nav-item" role="presentation">
                  <div
                    className={`nav-link ${isActiveList["requirementsStatus"]}`}
                  >
                    <Link
                      to={`requirementsStatus`}
                      className="text-decoration-none"
                      onClick={() => {
                        changeStyle("requirementsStatus");
                      }}
                    >
                      Requirements Status
                    </Link>
                  </div>
                </li>
              )}
            {!addNewProjectFlag &&
              projectTestSource !== "ADO" &&
              projectTestSource !== "" && (
                <li className="nav-item" role="presentation">
                  <div
                    className={`nav-link ${isActiveList["requirementsCoverage"]}`}
                  >
                    <Link
                      to={`requirementsCoverage`}
                      className="text-decoration-none"
                      onClick={() => {
                        changeStyle("requirementsCoverage");
                      }}
                    >
                      Requirements Coverage
                    </Link>
                  </div>
                </li>
              )}
            {/* <li
                className="nav-item "
                role="presentation"
                ref={perfDropDownMenuRef}
              >
                <div
                  id="dropdownMenuButton"
                  type="button"
                  className={`nav-link ${isActiveList["performance"]}`}
                  onClick={() => {
                    setPerfMenuSelected("request_stats");
                  }}
                >
                  <Link
                    to="/performance"
                    className="text-decoration-none"
                    onClick={() => {
                      changeStyle("performance");
                    }}
                  >
                    Performance
                  </Link>
                </div>
                <div
                  className={
                    "dropdown-menu " + (toShowPerfDropdown ? "show" : "")
                  }
                  aria-labelledby="dropdownMenuButton"
                >
                  <button className="nav-link dropdown-item">
                    <Link
                      to="/performance/requeststats"
                      className="text-decoration-none"
                      onClick={() => {
                        setPerfMenuSelected("request_stats");
                        setToShowPerfDropdown(false);
                      }}
                    >
                      Request Stats
                    </Link>
                  </button>
                  <button className="nav-link dropdown-item">
                    <Link
                      to="/performance/requeststatsfromlogs"
                      className="text-decoration-none"
                      onClick={() => {
                        setPerfMenuSelected("request_stats_from_logs");
                        setToShowPerfDropdown(false);
                      }}
                    >
                      Request Stats From Logs
                    </Link>
                  </button>
                  <button className="nav-link dropdown-item">
                    <Link
                      to="/performance/timelinereport"
                      className="text-decoration-none"
                      onClick={() => {
                        setPerfMenuSelected("timeline_report");
                        setToShowPerfDropdown(false);
                      }}
                    >
                      Timeline Report
                    </Link>
                  </button>
                  <button className="nav-link dropdown-item">
                    <Link
                      to="/performance/grafanareport"
                      className="text-decoration-none"
                      onClick={() => {
                        setPerfMenuSelected("grafana_report");
                        setToShowPerfDropdown(false);
                      }}
                    >
                      Grafana Report
                    </Link>
                  </button>
                </div>
              </li> */}
            {/* <li className="nav-item" role="presentation">
                <div className={`nav-link ${isActiveList["security"]}`}>
                  <Link
                    to="/security"
                    className="text-decoration-none"
                    onClick={() => {
                      changeStyle("security");
                    }}
                  >
                    Security
                  </Link>
                </div>
              </li> */}
            {!addNewProjectFlag &&
              projectTestSource !== "ADO" &&
              projectTestSource !== "" && (
                <li className="nav-item" role="presentation">
                  <div
                    className={`nav-link ${isActiveList["processCompliance"]}`}
                  >
                    <Link
                      to={`processCompliance`}
                      className="text-decoration-none"
                      onClick={() => {
                        changeStyle("processCompliance");
                      }}
                    >
                      Process Compliance
                    </Link>
                  </div>
                </li>
              )}
            {!addNewProjectFlag &&
              projectTestSource !== "ADO" &&
              projectTestSource !== "" && (
                <li className="nav-item" role="presentation">
                  <div className={`nav-link ${isActiveList["report"]}`}>
                    <Link
                      to={`report`}
                      className="text-decoration-none"
                      onClick={() => {
                        changeStyle("report");
                      }}
                    >
                      Reports
                    </Link>
                  </div>
                </li>
              )}
          </ul>

          <Routes>
            <Route
              path="releaseScope"
              element={
                <ReleaseScope
                  token={props.token}
                  changeStyle={changeStyle}
                  setCurrentProjectName={setCurrentProjectName}
                  currentProjectId={currentProjectId}
                  currentProjectName={currentProjectName}
                />
              }
            ></Route>
            <Route
              path="settings"
              element={
                <ProjectDetailsComponent
                  token={props.token}
                  changeStyle={changeStyle}
                  currentProjectId={currentProjectId}
                  setCurrentProjectId={props.setCurrentProjectId}
                  selectedProjectType={props.selectedProjectType}
                  setSelectedProjectType={props.setSelectedProjectType}
                  loggedUser={props.loggedUser}
                  setCurrentProjectName={setCurrentProjectName}
                />
              }
            ></Route>
            <Route
              path="testcases"
              element={
                <TestExecution
                  token={props.token}
                  changeStyle={changeStyle}
                  currentProjectId={currentProjectId}
                  setCurrentProjectId={props.setCurrentProjectId}
                />
              }
            ></Route>
            <Route
              path="testdevelopment"
              element={
                <TestDevelopment
                  token={props.token}
                  changeStyle={changeStyle}
                  currentProjectId={currentProjectId}
                  setCurrentProjectId={props.setCurrentProjectId}
                />
              }
            ></Route>
            <Route
              path="defects"
              element={
                <Defects
                  token={props.token}
                  changeStyle={changeStyle}
                  currentProjectId={currentProjectId}
                  setCurrentProjectId={props.setCurrentProjectId}
                />
              }
            ></Route>
            <Route
              path="steercodefects"
              element={
                <SteercoDefects
                  token={props.token}
                  changeStyle={changeStyle}
                  currentProjectId={currentProjectId}
                  setCurrentProjectId={props.setCurrentProjectId}
                />
              }
            ></Route>
            <Route
              path="requirementsCoverage"
              element={
                <RequirementsCoverage
                  token={props.token}
                  changeStyle={changeStyle}
                  currentProjectId={currentProjectId}
                  setCurrentProjectId={props.setCurrentProjectId}
                />
              }
            ></Route>
            <Route
              path="requirementsStatus"
              element={
                <RequirementsStatus
                  token={props.token}
                  changeStyle={changeStyle}
                  currentProjectId={currentProjectId}
                  setCurrentProjectId={props.setCurrentProjectId}
                />
              }
            ></Route>
            {/* <Route   path="/performance">
                <Performance
                  token={props.token}
                  changeStyle={changeStyle}
                  currentProjectId={currentProjectId}
                  setCurrentProjectId={props.setCurrentProjectId}
                  perfMenuSelected={perfMenuSelected}
                />
              </Route> */}
            {/* <Route   path="/security">
                <div>Security Under construction</div>
              </Route> */}
            <Route
              path="processCompliance"
              element={
                <ProcessCompliance
                  token={props.token}
                  changeStyle={changeStyle}
                  currentProjectId={currentProjectId}
                  setCurrentProjectId={props.setCurrentProjectId}
                />
              }
            ></Route>
            <Route
              path="report"
              element={
                <Report
                  token={props.token}
                  changeStyle={changeStyle}
                  currentProjectId={currentProjectId}
                  setCurrentProjectId={props.setCurrentProjectId}
                />
              }
            ></Route>
            {/* <Navigate from="/" to={"/:projectId/settings"} /> */}
          </Routes>
          {/* </Router> */}
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
